import { useEffect, useRef, useState } from 'react';

import { ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import Cookies from 'js-cookie';
// import { WebSocket } from 'mock-socket';
import {
  Navigate,
  redirect,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  createThread,
  createThreadEmbedding,
  getConferenceList,
  getDiscoverList,
  getHotTopic,
  getThreadMeta,
  getThreadTasks,
  getWorkflowList,
} from '../api.js';
import ReferenceView from '../Common/ChatPanal/ReferenceView';
import ArticleCard from '../Common/horizonNews.js';
import { i18n as i18nCommon } from '../Common/i18n/common.js';
import { useAlert } from '../utils/context/alert';
import { useHistory } from '../utils/context/history.js';
import { useLanguage } from '../utils/context/lang.js';
import { useLayout } from '../utils/context/layout.js';
import { clearLatestMessage , useWebSocketContext } from '../utils/context/main-websocket.js';
import { usePro } from '../utils/context/pro.js';
import { useUser } from '../utils/context/user.js';
import { getFinalAgent } from '../utils/helpers.js';

import { ConferenceCard, DiscoverCard, WorkflowCard } from './components/cards.js';
import { OneConversation } from './components/oneConversation.js';
import { OneRunningConversation } from './components/oneRunningConversation.js';
import SingleLineInput from './components/singleLineInput.js';
import TextInput from './components/textInput.js';
import { i18n as agent_i18n, agents } from './const/agent.js';
import { exampleQuestions } from './const/exampleQuestions.js';
import { i18n } from './i18n/chat.js';

const langMap = {
  en: 'EN',
  zh: 'CN',
  jp: 'JP',
};


export function AskDetail() {
  const navigate = useNavigate();
  const { isSocketOpen, latestMessage, sendMessage, clearLatestMessage } = useWebSocketContext();
  const showAlert = useAlert();
  const { showModal } = useUser();
  const location = useLocation();
  const { lang, selectLanguage } = useLanguage();
  const { answerLang, switchAnswerLang,isWebSearchOn } = usePro();
  const [ followups, setFollowups ] = useState([]);
  const [ newAskId, setNewAskId ] = useState(null);
  const [ focusArea, setFocusArea ] = useState(null);
  const [ forceSwitchValue, setForceSwitchValue ] = useState(undefined);
  const [ reachLimit, setReachLimit ] = useState(false);
  const bottomRef = useRef(null);
  const chatContainerRef = useRef(null);
  const { collapseSidebar } = useLayout();

  const {
    needFetchLatest,
    initialData,
    runningTask,
    threadAgentType,
    hotTopics,
    hotArticles,
    workflows,
    conferences,
  } = useLoaderData();
  const [ autoScroll, setAutoScroll ] = useState(true);
  const [ latestQuestion, setLatestQuestion ] = useState(null);
  const [ isFollowupFetching, setIsFollowupFetching ] = useState(false);
  const [ isEmbedding, setIsEmbedding ] = useState(false);
  const [ runningAnswer, setRunningAnswer ] = useState(null);
  const { askId } = useParams(); // path: ask/askId

  const [ searchParams ] = useSearchParams(); // page intermit state, will be finalized as threadID

  // {"thread_name":"hello","agent":"mindsearchrefer","task":{"task_mode":"noahllm","question":"hello"},"context":{"reference":"workflow id", "reference_type":"workflow"}}
  const [ q, contextUUID, contextType ] = [
    searchParams.get('q'),
    searchParams.get('contextUUID'),
    searchParams.get('contextType'),
  ];
  const contextTitle = location.state?.title;
  const mockClient = useRef(null);
  const [ paddingBottom, setPaddingBottom ] = useState('pb-12');
  const latestAskIdRef = useRef(newAskId); // Create a ref for the newAskId state
  const { updateHistory } = useHistory();
  console.log('========askId', askId);
  // this register the query for fetch the latest conversation
  const threadId = newAskId || askId;


  // Update the ref every time newAskId changes
  useEffect(() => {
    latestAskIdRef.current = threadId;
  }, [ threadId ]);

  useEffect(() => {
    if (location.pathname === '/ask/' || location.pathname === '/ask') {
      // Redirect to the home page
      setNewAskId(null);
      setLatestQuestion(null);
      setFollowups([]);
      setRunningAnswer(null);
      setIsFollowupFetching(false);
      // alert('back');
    }
  }, [ location ]);

  useEffect(() => {
    clearLatestMessage();
    return () => clearLatestMessage(); // Also clear on unmount
  }, [ location.pathname ]);
  // this resolves the situation when the initialData is loading
  useEffect(() => {
    if (needFetchLatest) {
      setIsFollowupFetching(true);
      setLatestQuestion(runningTask); // latestQuestion could from the loader data
    } else {
      setIsFollowupFetching(false);
      setLatestQuestion(null);
    }
  }, [ needFetchLatest, askId ]);

  // when a new thread is generated, update url and fetch the data
  useEffect(() => {
    if (newAskId) {
      window.history.replaceState(null, '', `/ask/${newAskId}`);
    }
  }, [ newAskId ]);

  // Add lastScrollTop to track scroll direction
  const lastScrollTop = useRef(0);

  // Add new state to track if a new question was triggered
  const [ newQuestionTriggered, setNewQuestionTriggered ] = useState(false);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 50;

      // Only update autoScroll if we're not in a new question state
      if (!newQuestionTriggered) {
        setAutoScroll(isAtBottom);
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [ newQuestionTriggered ]);

  // Reset newQuestionTriggered when the scroll completes
  useEffect(() => {
    if (newQuestionTriggered) {
      const timeoutId = setTimeout(() => {
        setNewQuestionTriggered(false);
      }, 1000); // Adjust timing as needed

      return () => clearTimeout(timeoutId);
    }
  }, [ newQuestionTriggered ]);

  useEffect(() => {
    if (q && isSocketOpen) {
      // createQuestion(q, [], false, "Clinical");
      if (contextUUID && [ 'discoverArticle', 'workflow' ].includes(contextType)) {
        // alert(`create question with ${q} and ${contextUUID}`);
        createQuestion(q, [], true, 'mindsearchrefer', {
          reference: contextUUID,
          reference_type: contextType,
          reference_title: location.state?.title,
        });
      } else { //mostly for buzz usecase
        createQuestion(q, [], true, 'mindsearch', {
          reference: contextUUID,
          reference_type: contextType,
          reference_title: location.state?.title,
        });
      }
    }
  }, [ q, contextUUID, isSocketOpen ]);

  const currentAgentType =
    runningTask?.agent || initialData?.results[0]?.agent || threadAgentType; // this value won't change, as it comes from the initial data

  const onSubmit = (text, attachments, useUpgrade) => {
    console.log('ppppppppppppppp, im here');
    if (isFollowupFetching) {
      return;
    }
    console.log({ text, attachments, useUpgrade });
    if (newAskId || askId) {
      console.log('follow up');
      submitFollowupQuestion(text, attachments, useUpgrade);
    } else {
      console.log("create normally wouldn't be triggered here...");
    }

    // Enable auto-scroll and set new question flag when submitting
    setAutoScroll(true);
    setNewQuestionTriggered(true);
  };

  // context is like "context":{"reference":"workflow id", "reference_type":"workflow"}}

  // Track Article Clicks in hotTopics
  const handleTopicClick = (itemId, itemTitle) => {
    window.gtag('event', 'click', {
      event_category: 'HotTopic',
      event_label: itemTitle,
    });
    navigate(`/ask/?q=${encodeURIComponent(itemTitle)}&contextUUID=${itemId}&contextType=buzz`);
  };

  // Track Article Clicks in hotArticles
  const handleArticleClick = (articleId) => {
    window.gtag('event', 'click', {
      event_category: 'HotArticle',
      event_label: `Article_${articleId}`,
    });
    navigate(`/discover/article/${articleId}`);
  };

  // Track Language Selection
  const handleLanguageChange = (e) => {
    const selectedLang = e.target.value;
    window.gtag('event', 'change_site_language', {
      event_category: 'Language',
      event_label: selectedLang,
    });
    switchAnswerLang(langMap[selectedLang]); // switch explore answer as well.
    selectLanguage(selectedLang);
  };

  const originalList = initialData?.results || [];
  const conversationList = originalList.map((item, index) => {
    // this is for the reference at top
    const handleReferenceClick = (ref) => {
      // console.log(12312313)
      console.log('top reference click', ref);
      setIsReferenceVisible(true);
      setCurrentReference(ref);
    };
    return (
      <OneConversation
        key={'' + index}
        item={item}
        onTriggerNewQuestion={onSubmit}
        onReferenceClick={handleReferenceClick}
      />
    );
  });
  const followupList = followups.map((item, index) => {
    // this is also for the reference at top
    const handleReferenceClick = (ref) => {
      // console.log(12312313)
      console.log('top reference click', ref);
      setIsReferenceVisible(true);
      setCurrentReference(ref);
    };
    return (
      <OneConversation
        key={'' + item.id || item.task_id + index}
        item={item}
        onTriggerNewQuestion={onSubmit}
        onReferenceClick={handleReferenceClick}
      />
    );
  });

  // Handle incoming WebSocket messages
  useEffect(() => {
    if (!latestMessage) return;

    if (!latestMessage.type || latestMessage.type === 'LIMIT') {
      showAlert('error', i18n.DAILY_LIMIT_ERROR[lang]);
      setIsFollowupFetching(false);
      setForceSwitchValue(false);
    } else if (latestMessage.type === 'CONTENT') {
      setRunningAnswer(latestMessage);

      if (latestMessage.status === 'DONE') {
        setIsFollowupFetching(false);
        setRunningAnswer(null);
        setFollowups((prev) => [ ...prev, latestMessage ]);
      }
    }
  }, [ latestMessage ]);

  // Reset states when location changes
  useEffect(() => {
    if (location.pathname === '/ask/' || location.pathname === '/ask') {
      setNewAskId(null);
      setLatestQuestion(null);
      setFollowups([]);
      setRunningAnswer(null);
      setIsFollowupFetching(false);
    }
  }, [ location ]);

  // Handle initial data loading
  useEffect(() => {
    if (needFetchLatest) {
      setIsFollowupFetching(true);
      setLatestQuestion(runningTask);
    } else {
      setIsFollowupFetching(false);
      setLatestQuestion(null);
    }
  }, [ needFetchLatest, askId ]);

  // Auto-scroll functionality
  useEffect(() => {
    if (autoScroll) {
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  }, [ followups, latestQuestion, paddingBottom, runningAnswer, autoScroll ]);

  const createQuestion = async (text, attachments, useUpgrade, focusArea, context) => {
    if (!isSocketOpen || isFollowupFetching) {
      showAlert('error', i18n.CONNECTION_ERROR[lang]);
      return;
    }

    setFocusArea(focusArea);
    setLatestQuestion({
      question: text,
      upload_files: attachments,
      agent: focusArea,
    });
    setIsFollowupFetching(true);

    try {
      const newThreadId = await createThread(
        text,
        getFinalAgent(useUpgrade, focusArea),
      );
      setNewAskId(newThreadId);
      updateHistory();

      sendMessage({
        thread_id: newThreadId,
        question: text,
        upload_files: attachments?.map(a => a.id),
        agent: getFinalAgent(useUpgrade, focusArea),
        enable_rag: isWebSearchOn,
        language: answerLang,
        context,
      });
    } catch (error) {
      setIsFollowupFetching(false);
      showAlert('error', error?.response?.data?.detail || error.message);
    }
  };

  const submitFollowupQuestion = async (text, attachments, useUpgrade) => {
    if (!isSocketOpen || isFollowupFetching) {
      showAlert('error', i18n.CONNECTION_ERROR[lang]);
      return;
    }

    setLatestQuestion({
      question: text,
      upload_files: attachments,
      agent: currentAgentType || focusArea,
    });
    setIsFollowupFetching(true);

    try {
      sendMessage({
        thread_id: threadId,
        question: text,
        upload_files: attachments?.map(a => a.id),
        agent: getFinalAgent(useUpgrade, currentAgentType || focusArea),
        language: answerLang,
      });
    } catch (error) {
      setIsFollowupFetching(false);
      showAlert('error', error?.response?.data?.detail || error.message);
    }
  };

  const [ isReferenceVisible, setIsReferenceVisible ] = useState(false);
  const [ currentReference, setCurrentReference ] = useState(null);

  // Add reference click handler
  // const handleReferenceClick = (ref) => {
  //   // console.log(12312313)
  //   setIsReferenceVisible(true);
  //   setCurrentReference(ref);
  // };

  // Handle responsive sidebar collapse
  useEffect(() => {
    const handleResize = () => {
      // IMPORTANT: manually collapse the sidebar won't trigger the resize event, which is FANTASTIC!
      if (chatContainerRef.current) {
        const containerWidth = chatContainerRef.current.offsetWidth;
        if (containerWidth < 400) {
          collapseSidebar();
        }
      }
    };

    // Initial check
    handleResize();

    // Add resize listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!askId && !newAskId && !latestQuestion && !q) {
    return (
      <div className="flex flex-col min-h-[800px] h-screen bg-base-100">
        {/* Main content area with minimum spacing from top */}
        <main className="flex-1 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 sm:pt-12">
          <div className="max-w-5xl mx-auto">
            {/* Cards Grid - with fixed vertical spacing */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Workflow Cards */}
              {workflows?.map(workflow => (
                <WorkflowCard key={workflow.id} workflow={workflow} />
              ))}
              
              {/* Conference Card */}
              <ConferenceCard conference={conferences} />
              
              {/* Discover Card */}
              <DiscoverCard article={hotArticles} />
            </div>
          </div>
        </main>

        {/* Input Section - fixed spacing from bottom */}
        <div className="flex-none w-full px-4 sm:px-6 lg:px-8 xl:px-0 mt-8">
          <div className="max-w-5xl mx-auto">
            <TextInput
              className="w-full mb-8"
              onSubmit={(res) => {
                window.gtag('event', 'form', {
                  event_category: 'explore_create',
                  event_label: 'direct',
                  focus_area: res.focusArea,
                  pro: res.switchValue,
                  text_input: res.textInput,
                });
                createQuestion(
                  res.textInput,
                  res.uploadInfos,
                  res.switchValue,
                  res.focusArea
                );
              }}
              enableUpload={true}
              disabled={false}
              onFileCountChange={(count) => {
                console.log(count);
              }}
            />
          </div>
        </div>

        {/* Footer - fixed height */}
        {/* <footer className="flex-none h-16 w-full px-4 py-4 text-secondary-content text-center bg-base-100">
          <a href="/about" className="mr-4 hover:underline">{i18n.ABOUT[lang]}</a>
          <select
            onChange={handleLanguageChange}
            className="border-none bg-transparent focus:ring-0 focus:outline-none cursor-pointer"
          >
            <option value="en" selected={lang === 'en'}>English</option>
            <option value="zh" selected={lang === 'zh'}>中文</option>
          </select>
        </footer> */}
      </div>
    );
  }
  return (
    // isSocketOpen &&
    <div className={`relative h-screen ${
      isReferenceVisible && currentReference?.url?.toLowerCase().includes('.pdf') 
        ? 'lg:mr-[800px]' 
        : isReferenceVisible 
          ? 'lg:mr-[400px]' 
          : 'lg:mr-0'
    }`}>
      {/* <Slider>
        <PdfViewer url={'/pdf/US-KOL-Wet_AMD_WA6.pdf'} pageNumber={4} />
      </Slider> */}
      <div
        className={`absolute bg-base-100 w-full h-full flex-1 overflow-y-scroll ${paddingBottom}`}
        ref={chatContainerRef}
      >
        {/* {contextType && <p>{agent_i18n["mindsearchrefer"][lang]}</p>}
        {threadAgentType && <p>{agent_i18n[threadAgentType][lang]}</p>} */}
        <div className="px-12 lg:px-16 mx-auto text-base leading-7 text-secondary-content mt-12">
          {/* {answerSection} */}
          {/* <HelloMsg agentType={currentAgentType} onSubmit={onSubmit} /> */}
          {location.state?.sourceURL && (
            <div
              className="max-w-5xl mb-4 mx-auto"
              onClick={() => {
                window.open(location.state?.sourceURL, '_blank');
              }}
            >
              <div className="cursor-pointer bg-base-300 rounded-lg p-4 mx-auto">
                <div className="mr-2 flex text-md items-center">
                  <ViewfinderCircleIcon className="w-4 h-4 mr-2" />
                  {i18nCommon.CONTEXT_TITLE[lang]}
                </div>
                <p className="text-lg font-bold">{location.state?.title}</p>
              </div>
            </div>
          )}

          {conversationList}
          {followupList}
          {isFollowupFetching && (
            <OneRunningConversation
              item={latestQuestion}
              runningAnswer={runningAnswer}
              isEmbedding={isEmbedding}
            />
          )}
          <div ref={bottomRef} className="h-6"></div>
        </div>
      </div>

      {/* Philosophy: detail is just like chatpanal without reference, so it needs a referenceView TODO: ziwen*/}
      {/* Reference Panel with Animation */}
      <AnimatePresence>
        {isReferenceVisible && currentReference && (
          <motion.div
            initial={{ x: 400, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 400, opacity: 0 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="fixed top-0 right-0 h-screen z-40 border-l border-base-200"
          >
            <ReferenceView 
              reference={currentReference}
              isChatVisible={true}
              onClose={() => {
                setIsReferenceVisible(false);
                setCurrentReference(null);
              }}
              onOpenChat={() => {}}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <SingleLineInput
        className="absolute bottom-3 md:w-3/4 lg:w-2/3 w-full mx-auto left-0 right-0"
        onSubmit={onSubmit}
        // enableUpload={
        //   agents.filter((agent) => agent.category === currentAgentType).at(0)
        //     .enableUpload
        // }
        enableUpload={true}
        disabled={isFollowupFetching}
        onFileCountChange={(count) => {
          if (count > 0) {
            setPaddingBottom('pb-32');
          } else {
            setPaddingBottom('pb-12');
          }
        }}
      />
    </div>
  );
}

export async function askDetailLoader({ params }) {
  let runningTask = {};
  if (!params.askId) {
    const hotTopics = await getHotTopic(1, 3);
    const hotArticles = await getDiscoverList(1, 3);
    const workflows = await getWorkflowList(1, 3);
    const conferences = await getConferenceList(1, 3);
    console.log(hotTopics, hotArticles, workflows, conferences);
    return {
      needFetchLatest: false,
      initialData: null,
      runningTask: null,
      threadAgentType: null,
      hotTopics: hotTopics?.results,
      hotArticles:hotArticles,
      workflows: workflows,
      conferences: conferences,
    };
  }
  const initialData = await getThreadTasks(params.askId);
  const thread = await getThreadMeta(params.askId);
  const needFetchLatest =
    initialData?.results?.at(0) &&
    ![ 'complete', 'failed' ].includes(initialData?.results?.at(0)?.task_status);
  if (needFetchLatest) {
    runningTask = initialData.results.splice(0, 1)?.at(0);
  }
  initialData.results.reverse();
  // console.log("===", {needFetchLatest,initialData});
  return {
    needFetchLatest,
    initialData,
    runningTask,
    threadAgentType: thread?.agent,
    hotTopics: null,
    hotArticles: null,
  };
}
