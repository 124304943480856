import DisplayFormattedText from '../../../../AskPage/components/markdownInCell';

export const CONFERENCE_COLUMN = [
  {
    headerName: '#',
    // Convert zero-based rowIndex to a 1-based display:
    valueGetter: (params) => {                         
      return params.node.rowIndex + 1;
    },
    pinned: 'left',
    sortable: false,
    filter: false,
    width: 80,
    suppressMovable: true,
    comparator: () => 0,
    field: 'rowIndex' // Add field name for refresh
  },
  {
    headerName: 'Title',
    field: 'citation_title',
    width: 400,
    wrapText: true,
    pinned: 'left',
    cellRenderer: (params) => {
      return (
        <a
          href={params.data['link'] || 'https://apsard.org/wp-content/uploads/2025/01/2025-APSARD-Abstract-Book.pdf'}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 hover:text-blue-700 focus:outline-none focus:underline"
          dangerouslySetInnerHTML={{ __html: params.data['citation_title'] }}
        />
      );
    },
  },
  {
    headerName: 'Design',
    field: 'design',
    width: 200,
    wrapText: true,
  },
  {
    headerName: 'Efficacy',
    field: 'efficacy',
    width: 500,
    wrapText: true,
    cellRenderer: (params) => {
      if (!params.data['efficacy'] || params.data['efficacy'] === 'Not Reported') return null;
      return <DisplayFormattedText text={params.data['efficacy']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Safety',
    field: 'safety',
    width: 400,
    wrapText: true,
    cellRenderer: (params) => {
      if (!params.data['safety'] || params.data['safety'] === 'Not Reported') return null;
      return <DisplayFormattedText text={params.data['safety']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Phase',
    field: 'phase',
    width: 110,
    wrapText: true,
  },
  {
    headerName: 'Drugs',
    field: 'drug',
    width: 200,
    wrapText: true,
    cellRenderer: (params) => {
      if (!params.data['drug']?.length) return null;
      return <DisplayFormattedText text={params.data['drug'].map(d => `- ${d}`).join('\n')} className="text-xs"/>;
    },
  },
  {
    headerName: 'Targets',
    field: 'target',
    width: 200,
    wrapText: true,
    cellRenderer: (params) => {
      if (!params.data['target']?.length) return null;
      return <DisplayFormattedText text={params.data['target'].map(t => `- ${t}`).join('\n')} className="text-xs"/>;
    },
  },
  {
    headerName: 'Disease',
    field: 'disease',
    width: 180,
    wrapText: true,
  },

  {
    headerName: 'Presentation',
    field: 'presentation_type',
    width: 200,
  },
  {
    headerName: 'Author',
    field: 'citation_author',
    width: 150,
    wrapText: true,
  },
  {
    headerName: 'Institution',
    field: 'citation_author_institution',
    width: 200,
    wrapText: true,
  },
  // {
  //   headerName: 'Abstract',
  //   field: 'abstract',
  //   width: 600,
  //   wrapText: true,
  //   cellRenderer: (params) => {
  //     return <DisplayFormattedText text={params.data['abstract']} className="text-xs"/>;
  //   },
  // },
  // {
  //   headerName: 'Study Type',
  //   field: 'study_type',
  //   width: 250,
  //   wrapText: true,
  // },
]; 