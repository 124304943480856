import React, { useEffect, useRef, useState } from 'react';


import {
  ArrowTopRightOnSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { useLocation , useNavigate } from 'react-router-dom';

import { createThread } from '../../api';
import { OneConversation } from '../../AskPage/components/oneConversation';
import { OneRunningConversation } from '../../AskPage/components/oneRunningConversation';
import SingleLineInput from '../../AskPage/components/singleLineInput';
import { useAlert } from '../../utils/context/alert';
import { useLanguage } from '../../utils/context/lang';
import { useWebSocketContext } from '../../utils/context/main-websocket';
import { usePro } from '../../utils/context/pro';
import { i18n as chatI18n } from '../i18n/chat';
import { i18n as commonI18n } from '../i18n/common';

import ReferenceView from './ReferenceView';
import selectedTips from './selected-tips.gif';
import SelectedItems from './SelectedItems';

const langMap = {
  en: 'EN',
  zh: 'CN',
  jp: 'JP',
};

const getHintMessage = (assetType, assetId, lang) => {
  const messages = {
    discover: '',
    conference: chatI18n.HINT_CONFERENCE[lang],
    default: '',
    workflow: {
      'clinical-result': chatI18n.HINT_CLINICAL_RESULT[lang],
      'drug-compete': chatI18n.HINT_DRUG_COMPETE[lang],
    }
  };
  if (assetType === 'workflow') {
    return messages.workflow[assetId] || messages.default;
  }
  return messages[assetType] || messages.default;
};

const ChatPanel = ({
  enableUpload = false,
  className = '',
  isOpen = true,
  onToggle,
  initialConversations = [],
  suggestions = [],
  reference = null,
  onReferenceClose = () => {},
  assetId = null,
  assetType = null,
  assetTitle = null,
  threadId = null,
  onThreadCreated = () => {},
  selectedItems = [],
  onItemRemove,
  itemType,
}) => {
  const [ isMobile, setIsMobile ] = useState(false);
  const [ isChatVisible, setIsChatVisible ] = useState(isOpen);
  const [ isReferenceVisible, setIsReferenceVisible ] = useState(false);
  const [ conversations, setConversations ] = useState(initialConversations);
  const [ isFollowupFetching, setIsFollowupFetching ] = useState(false);
  const [ latestQuestion, setLatestQuestion ] = useState(null);
  const [ runningAnswer, setRunningAnswer ] = useState(null);
  const [ currentReference, setCurrentReference ] = useState(reference); // Track current active reference
  const navigate = useNavigate();
  const chatContainerRef = useRef(null);
  const bottomRef = useRef(null);
  const { lang } = useLanguage();
  const showAlert = useAlert();
  const { isSocketOpen, latestMessage, sendMessage, clearLatestMessage } = useWebSocketContext();
  const { isWebSearchOn } = usePro();
  const location = useLocation();
  const [ newQuestionTriggered, setNewQuestionTriggered ] = useState(false);
  const [ autoScroll, setAutoScroll ] = useState(true);

  // Track internal thread ID to prevent premature refreshes
  const previousThreadId = useRef(threadId);

  // Handle thread changes
  useEffect(() => {
    // Skip if it's the same thread or it's a new thread
    if (threadId === previousThreadId.current || !previousThreadId.current) {
      return;
    }

    // Update reference and reset state
    previousThreadId.current = threadId;
    clearLatestMessage();
    setConversations(initialConversations);
    setLatestQuestion(null);
    setRunningAnswer(null);
    setIsFollowupFetching(false);
    if (!threadId && !reference) {
      setIsChatVisible(true);
      setIsReferenceVisible(false);
    }
  }, [ assetId, assetType, threadId, initialConversations, clearLatestMessage ]);

  // Clear message only on unmount
  useEffect(() => {
    return () => clearLatestMessage();
  }, []);

  useEffect(() => {
    console.log('222222222')
    if (reference) {
      setCurrentReference(reference);
      setIsReferenceVisible(true);
      setIsChatVisible(true);
      onToggle(true);
    }
  }, [ reference ]);

  useEffect(() => {
    setIsChatVisible(isOpen);
  }, [ isOpen ]);

  // Handle incoming WebSocket messages
  useEffect(() => {
    console.log('333333333', latestMessage)
    if (!latestMessage) return;

    if (!latestMessage.type || latestMessage.type === 'LIMIT') {
      showAlert('error', commonI18n.DAILY_LIMIT_ERROR[lang]);
      setIsFollowupFetching(false);
    } else if (latestMessage.type === 'CONTENT') {
      // NOTE: avoid to update the running answer when the threadId is not the same as the latestMessage.id
      if (latestMessage.thread_id !== threadId) {
        return;
      }
      setRunningAnswer(latestMessage);

      if (latestMessage.status === 'DONE') {
        setIsFollowupFetching(false);
        setRunningAnswer(null);
        setConversations((prev) => [ ...prev, latestMessage ]);
      }
    }
  }, [ latestMessage ]);

  // Add new state for tracking new questions

  // Replace the existing scroll effect
  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 50;

      // Only update autoScroll if we're not in a new question state
      if (!newQuestionTriggered) {
        setAutoScroll(isAtBottom);
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [ newQuestionTriggered ]);

  // Modify the existing scroll effect
  useEffect(() => {
    if (autoScroll) {
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  }, [ conversations, latestQuestion, runningAnswer, autoScroll ]);

  // Reset newQuestionTriggered when the scroll completes
  useEffect(() => {
    if (newQuestionTriggered) {
      const timeoutId = setTimeout(() => {
        setNewQuestionTriggered(false);
      }, 1000); // Adjust timing as needed

      return () => clearTimeout(timeoutId);
    }
  }, [ newQuestionTriggered ]);

  // Add effect to reset panel state when assetId or threadId changes
  useEffect(() => {
    // Reset all chat-related states
    setConversations(initialConversations);
    setLatestQuestion(null);
    setRunningAnswer(null);
    setIsFollowupFetching(false);
  }, [ assetId, initialConversations ]); // Dependencies that should trigger reset

  const handleSubmit = async (text, attachments = [], useUpgrade = false) => {
    if (isFollowupFetching || !isSocketOpen) {
      showAlert('error', commonI18n.CONNECTION_ERROR[lang]);
      return;
    }

    setAutoScroll(true);
    setNewQuestionTriggered(true);
    setLatestQuestion({
      question: text,
      upload_files: attachments,
    });
    setIsFollowupFetching(true);
    
    try {
      let currentThreadId = threadId;
      if (!currentThreadId) {
        currentThreadId = await createThread(
          text,
          assetType === 'discover' ? 'mindsearchrefer' : 'mindsearchworkflowrefer',
          assetType,
          assetId,
        );
        onThreadCreated(currentThreadId);
      }

      sendMessage({
        thread_id: currentThreadId,
        question: text,
        upload_files: attachments?.map((attachment) => attachment.id),
        enable_rag: isWebSearchOn,
        agent: assetType === 'discover' ? 'mindsearchrefer' : 'mindsearchworkflowrefer',
        language: langMap[lang].toUpperCase(),
        context: {
          ids: [
            ...selectedItems.map((item) => item.id),
          ],
          query: {},
        },
      });
    } catch (error) {
      setIsFollowupFetching(false);
      showAlert('error', error.message);
    }
  };

  // Calculate total width based on visible panels
  const getTotalWidth = () => {
    if (isChatVisible && isReferenceVisible) {
      // If showing PDF, return 1200 (666 + 800), else return 800 (666 + 400)
      const referenceWidth = currentReference?.url?.toLowerCase().includes('.pdf') ? 800 : 400;
      return 666 + referenceWidth;
    } else if (isChatVisible) {
      return 666;
    } else if (isReferenceVisible) {
      // If showing only reference and it's a PDF, return 800, else return 400
      return 400;
    }
    return 0;
  };

  const handleReferenceClick = (ref) => {
    console.log('chat panel reference', ref);
    setIsReferenceVisible(true);
    setIsChatVisible(true);
    setCurrentReference(ref);
    onToggle(true);
  };

  return (
    <>
      {/* Always visible toggle button */}
      <motion.div
        onClick={() => {
          if (!isChatVisible && !isReferenceVisible) {
            setIsChatVisible(true);
            onToggle(true);
          } else {
            setIsChatVisible(false);
            setIsReferenceVisible(false);
            onToggle(false);
          }
        }}
        className="fixed top-1/2 -translate-y-1/2 z-40 cursor-pointer"
        animate={{
          right: getTotalWidth(),
        }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
      >
        <div className="flex items-center">
          <div className="relative group">
            <div className="w-4 h-16 bg-base-300/50 backdrop-blur-sm rounded-l-md hover:bg-base-300 transition-all duration-200">
              <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
                            flex flex-col gap-1 transition-opacity duration-200 opacity-100`}>
                <div className="w-0.5 h-4 bg-secondary-content rounded-full"></div>
              </div>
            </div>
            
            {/* Optional: Tooltip */}
            <div className="absolute right-6 top-1/2 -translate-y-1/2 px-2 py-1 
                          bg-base-300 rounded text-xs whitespace-nowrap opacity-0 
                          group-hover:opacity-100 transition-opacity duration-200">
              {(isChatVisible || isReferenceVisible) ? chatI18n.HIDE_PANEL[lang] : chatI18n.SHOW_PANEL[lang]}
            </div>
          </div>
        </div>
      </motion.div>

      {/* Panel Container */}
      <motion.div
        initial={false}
        animate={{
          x: isChatVisible || isReferenceVisible ? 0 : 666,
          width: getTotalWidth(),
        }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        className={`fixed top-0 right-0 z-40 h-screen border-l border-base-200 bg-base-100 flex ${className}`}
      >
        {/* Chat Section */}
        {isChatVisible && (
          <div className="w-[666px] flex flex-col">
            <div className="flex-none p-4 border-b border-base-200 flex justify-between items-center">
              <h3 className="text-lg font-semibold">{chatI18n.CHAT[lang]}</h3>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => {
                    const url = assetType === 'discover' ? `/discover/article/${assetId}` : `/${assetType}/${assetId}`;
                    navigate(url);
                  }}
                  className="p-1.5 hover:bg-base-200 rounded-lg transition-colors duration-200 tooltip tooltip-left"
                  data-tip={chatI18n.CREATE_NEW[lang]}
                >
                  <PencilSquareIcon className="w-5 h-5" />
                </button>
              </div>
            </div>

            {/* Add Selected Items */}
            {selectedItems?.length > 0 && (
              <div className="max-h-[200px] overflow-y-auto">
                <SelectedItems 
                  items={selectedItems}
                  onRemove={onItemRemove}
                  type={itemType}
                />
              </div>
            )}

            {/* Chat Messages */}
            <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4">
              {/* Add Hint Message when no conversations */}
              {selectedItems.length === 0 && conversations.length === 0 && !isFollowupFetching && getHintMessage(assetType, assetId, lang) && (
                <div className="bg-base-200/50 backdrop-blur-sm rounded-lg p-4 mb-4">
                  <p className="text-sm text-gray-600">
                    {getHintMessage(assetType, assetId, lang)}
                  </p>
                  {
                    getHintMessage(assetType, assetId, lang) && (
                      <div className="mt-4 flex justify-center">
                        <img 
                          src={selectedTips} 
                          alt="Selected Tips"
                          className="rounded-lg shadow-md max-w-full h-auto object-contain"
                          style={{ maxHeight: '200px' }}
                        />
                      </div>
                    )
                  }
                </div>
              )}

              {/* Suggestions Section */}
              {suggestions.length > 0 &&
                conversations.length === 0 &&
                !isFollowupFetching && (
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-3">
                    {commonI18n.RELATED[lang]}
                  </h3>
                  <ul className="space-y-2">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        className="group relative min-w-0 flex justify-between items-center py-2 border-b border-base-200 cursor-pointer rounded-sm transition-colors duration-200"
                        onClick={() => handleSubmit(suggestion)}
                      >
                        <div className="flex-1 pr-6">
                          <p className="text-sm leading-6 text-primary-color line-clamp-2 group-hover:underline">
                            {suggestion}
                          </p>
                        </div>
                        <PlusIcon className="w-5 h-5 text-primary-color flex-shrink-0" />
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Conversations */}
              {conversations.map((item, index) => (
                <OneConversation
                  key={index}
                  item={item}
                  onTriggerNewQuestion={handleSubmit}
                  onReferenceClick={handleReferenceClick}
                />
              ))}

              {/* Running Conversation */}
              {isFollowupFetching && (
                <OneRunningConversation
                  item={latestQuestion}
                  runningAnswer={runningAnswer}
                />
              )}

              <div ref={bottomRef} />
            </div>

            {/* Input Section */}
            <div className="flex-none p-4 border-t border-base-200">
              <SingleLineInput
                onSubmit={handleSubmit}
                enableUpload={enableUpload}
                disabled={isFollowupFetching}
                className="w-full"
                placeholder={chatI18n.TYPE_MESSAGE[lang]}
                onFileCountChange={(count) => {
                  console.log('count', count);
                }}
              />
            </div>
          </div>
        )}

        {/* Reference Section */}
        {isReferenceVisible && currentReference && (
          <ReferenceView 
            reference={currentReference}
            isChatVisible={isChatVisible}
            onClose={() => {
              setIsReferenceVisible(false);
              onReferenceClose();
              if (!isChatVisible) onToggle(false);
            }}
            onOpenChat={() => setIsChatVisible(true)}
          />
        )}
      </motion.div>

      {/* Mobile overlay */}
      {isMobile && (isChatVisible || isReferenceVisible) && (
        <div
          className="fixed inset-0 bg-black/20 z-30"
          onClick={() => {
            setIsChatVisible(false);
            setIsReferenceVisible(false);
            onToggle(false);
          }}
        />
      )}
    </>
  );
};

export default ChatPanel;
