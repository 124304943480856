export const i18n = {
  TITLE: {
    en: 'Medical Conferences',
    zh: '医学会议',
  },
  FILTER_YEAR: {
    en: 'Year',
    zh: '年份',
  },
  FILTER_TYPE: {
    en: 'Conference Type',
    zh: '会议类型',
  },
  ALL_YEARS: {
    en: 'All Years',
    zh: '所有年份',
  },
  ALL_TYPES: {
    en: 'All Types',
    zh: '所有类型',
  },
  CLEAR_FILTERS: {
    en: 'Clear Filters',
    zh: '清除筛选',
  },
  // Conference types
  CONFERENCE_TYPES: {
    ASCO: { en: 'ASCO', zh: 'ASCO美国临床肿瘤学会' },
    ESMO: { en: 'ESMO', zh: 'ESMO欧洲肿瘤内科学会' },
    ASH: { en: 'ASH', zh: 'ASH美国血液学会' },
    WCLC: { en: 'WCLC', zh: 'WCLC世界肺癌大会' },
  },
  // ... add more translations as needed
}; 