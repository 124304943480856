import React from 'react';

import { useLanguage } from '../utils/context/lang';

const i18n = {
  NO_RESULTS: {
    en: 'No Results Found',
    zh: '未找到结果'
  },
  ADJUST_SEARCH: {
    en: 'Try adjusting your search criteria or filters to find what you are looking for.',
    zh: '请尝试调整搜索条件或筛选条件以找到您要查找的内容。'
  }
};

export default function EmptyState() {
  const { lang } = useLanguage();
  
  return (
    <div className="flex flex-col items-center justify-center py-12 px-4">
      <div className="bg-base-200/50 backdrop-blur-sm rounded-xl p-8 max-w-lg w-full text-center">
        <div className="mb-4">
          <svg className="mx-auto h-12 w-12 text-base-content/30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <h3 className="text-lg font-semibold text-base-content mb-2">
          {i18n.NO_RESULTS[lang]}
        </h3>
        <p className="text-base-content/60 text-sm mb-6">
          {i18n.ADJUST_SEARCH[lang]}
        </p>
      </div>
    </div>
  );
} 