import React, { createContext, useContext, useState } from 'react';

const LayoutContext = createContext();

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};

export const LayoutProvider = ({ children }) => {
  const [ isSidebarCollapsed, setIsSidebarCollapsed ] = useState(false);

  const collapseSidebar = () => setIsSidebarCollapsed(true);
  const expandSidebar = () => setIsSidebarCollapsed(false);
  const toggleSidebar = () => setIsSidebarCollapsed(prev => !prev);

  return (
    <LayoutContext.Provider value={{
      isSidebarCollapsed,
      setIsSidebarCollapsed,
      collapseSidebar,
      expandSidebar,
      toggleSidebar,
    }}>
      {children}
    </LayoutContext.Provider>
  );
}; 