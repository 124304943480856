import React, { useEffect, useRef, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { useLayout } from '../../utils/context/layout';

// import PdfViewer from './pdf';

const ReferenceView = ({ 
  reference,
  isChatVisible,
  onClose,
  onOpenChat,
}) => {
  // reference be like { sources: [...], referenceIndex: 0 }
  console.log('!!!', reference)
  const { collapseSidebar } = useLayout();
  const [ isLoading, setIsLoading ] = useState(false);
  const referencesContainerRef = useRef(null);

  // Auto-detect reference type
  const isPDF = reference.sources?.[reference.referenceIndex]?.url?.toLowerCase().includes('.pdf');
  const hasSourcesList = Array.isArray(reference.sources) && reference.sources.length > 0;
  const urlObj = isPDF ? new URL(reference.sources?.[reference.referenceIndex]?.url) : null;
  const pageNumber = urlObj ? parseInt(urlObj.searchParams.get('page_num')) || 1 : null;

  // Scroll to highlighted reference when reference list is shown
  useEffect(() => {
    if (hasSourcesList && reference.referenceIndex !== undefined && referencesContainerRef.current) {
      const referenceElements = referencesContainerRef.current.children;
      if (referenceElements[reference.referenceIndex]) {
        referenceElements[reference.referenceIndex].scrollIntoView({ 
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }, [ hasSourcesList, reference.referenceIndex ]);

  useEffect(() => {
    if (isPDF) {
      collapseSidebar();
    }
  }, [ isPDF ]);

  return (
    <div className={`flex flex-col h-screen border-l border-base-200 ${
      isPDF ? 'w-[800px]' : 'w-[400px]'
    }`}>
      <div className="flex-none p-4 border-b border-base-200">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold">
            {hasSourcesList ? 'References' : 'Reference'}
          </h3>
          <div className="flex gap-2">
            {!isChatVisible && (
              <button
                onClick={onOpenChat}
                className="text-sm px-2 py-1 hover:bg-base-200 rounded transition-colors duration-200"
              >
                Open Chat
              </button>
            )}
            <button
              onClick={onClose}
              className="p-1 hover:bg-base-200 rounded-full transition-colors duration-200"
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex-1 relative overflow-hidden">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-base-100/50 z-10">
            <div className="flex flex-col items-center">
              <div className="loading loading-spinner loading-lg mb-4"></div>
              <div className="text-sm text-base-content/70">
                Loading reference...
              </div>
            </div>
          </div>
        )}

        {isPDF ? (
          <div className="h-full">
            {/* <PdfViewer 
              url={reference.sources?.[reference.referenceIndex]?.url}
              pageNumber={pageNumber}
              onLoadStart={() => setIsLoading(true)}
              onLoadComplete={() => setIsLoading(false)}
            /> */}
          </div>
        ) : hasSourcesList ? (
          <div className="h-full overflow-y-auto">
            <div 
              ref={referencesContainerRef}
              className="p-4 space-y-4 h-full overflow-y-scroll"
            >
              {reference.sources?.map((source, index) => (
                <div
                  href={source.url}
                  target="_blank"
                  rel="noreferrer"
                  key={index} 
                  className={`p-4 rounded-lg transition-colors duration-200 ${
                    index === reference.referenceIndex 
                      ? 'bg-primary-color/10 border-2 border-primary-color'
                      : 'bg-base-200/50 hover:bg-base-200'
                  }`}
                >
                  <a
                    href={source.url}
                    target="_blank"
                    rel="noreferrer"
                    className="font-medium mb-2 line-clamp-2 hover:underline"
                  >
                    {source.title}
                  </a>
                  {source.summary && (
                    <p className="text-sm text-base-content/70">{source.summary}</p>
                  )}
                  <p className="text-sm text-primary-color inline-block mt-2"
                  >
                    {source.site_name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="p-4">
            <h4 className="font-medium mb-2">{reference.title}</h4>
            <a
              href={reference.link || reference.url}
              target="_blank"
              rel="noreferrer"
              className="text-sm text-primary-color hover:underline"
            >
              {reference.link || reference.site_name}
            </a>
            {reference.description && (
              <p className="mt-4 text-sm">{reference.description}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferenceView; 