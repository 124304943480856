import { lazy } from 'react';

// Lazy load components
const NctIdInput = lazy(() => import('./nctid-input'));
const DrugInput = lazy(() => import('./drug-input'));
const CompanySelect = lazy(() => import('./company-select'));
const DiseaseInput = lazy(() => import('./disease-tree'));
const TargetInput = lazy(() => import('./target-input'));
const LocationSelect = lazy(() => import('./location-select'));
const GenderSelect = lazy(() => import('./gender-select'));
const StudyTypeSelect = lazy(() => import('./study-type-select'));
const StatusSelect = lazy(() => import('./status-select'));
const PhaseSelect = lazy(() => import('./phase-select'));
const PhaseSelectCompete = lazy(() => import('./phase-select-compete'));
const RouteInput = lazy(() => import('./route-input'));
const DrugTypeSelect = lazy(() => import('./drug-type-select'));
const DrugFeatureSelect = lazy(() => import('./drug-feature-select'));
const DrugModalityTree = lazy(() => import('./drug-modality-tree'));
const MiscInput = lazy(() => import('./misc-input'));

export const FIELD_COMPONENTS = {
  NctIdInput,
  DrugInput,
  CompanySelect,
  DiseaseInput,
  TargetInput,
  LocationSelect,
  GenderSelect,
  StudyTypeSelect,
  StatusSelect,
  PhaseSelect,
  PhaseSelectCompete,
  RouteInput,
  DrugTypeSelect,
  MiscInput,
  DrugFeatureSelect,
  DrugModalityTree,
};



