import React from 'react';

import { motion } from 'framer-motion';

import { selectionStyles } from '../../styles/selection';

const Badge = ({ children, variant = 'default' }) => {
  const variants = {
    phase: 'bg-primary/80 text-primary-content border border-primary/20',
    target: 'bg-info/10 text-primary-content border border-info/20',
    type: 'bg-secondary/80 text-secondary-content border border-secondary/20',
    indication: 'bg-accent/10 text-accent border border-accent/20',
    default: 'bg-base-200 text-base-content/60'
  };

  return (
    <span className={`px-2.5 py-1 text-xs font-medium rounded-full inline-flex items-center ${variants[variant]}`}>
      {children}
    </span>
  );
};

const InfoSection = ({ label, children }) => (
  <div className="space-y-1.5">
    <div className="text-xs text-base-content/50">{label}</div>
    <div className="text-sm text-base-content">{children}</div>
  </div>
);

const CompeteCard = ({ item, isSelected, onClick, lang = 'en' }) => {
  return (
    <motion.div
      onClick={onClick}
      whileHover={{ y: -2, boxShadow: '0 4px 20px rgba(0,0,0,0.1)' }}
      whileTap={{ scale: 0.98 }}
      className={`bg-base-100 rounded-xl overflow-hidden transition-all duration-300 cursor-pointer relative
        ${isSelected 
      ? 'border-[1.5px] border-primary ring-1 ring-primary/30 shadow-lg shadow-primary/5' 
      : 'border border-base-200 hover:shadow-md'}`}
    >
      {/* Selection Indicator */}
      <div className={selectionStyles.container}>
        <div className={`${selectionStyles.checkbox} 
          ${isSelected ? selectionStyles.selected : selectionStyles.unselected}`}
        >
          {isSelected && (
            <svg className={selectionStyles.checkIcon} viewBox="0 0 24 24">
              <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
            </svg>
          )}
        </div>
      </div>

      <div className="p-6">
        {/* Header Section */}
        <div className="mb-5">
          <h3 className="text-lg font-semibold text-base-content mb-2 pr-6">
            {item.name}
          </h3>
          <div className="text-sm font-medium text-base-content/70">
            {item.lead_company}
          </div>
        </div>

        {/* Status Section */}
        <div className="flex flex-wrap gap-2 mb-5">
          {item.phase && (
            <Badge variant="phase">
              Phase {item.phase?.replace('Phase ', '')}
            </Badge>
          )}
          <Badge variant="indication">
            {item.indication}
          </Badge>
        </div>

        {/* Details Section */}
        <div className="space-y-5">
          {/* Targets */}
          {item.target?.length > 0 && (
            <InfoSection label={
              <span className="font-medium uppercase tracking-wider text-[11px]">Targets</span>
            }>
              <div className="flex flex-wrap gap-2">
                {item.target.map((target, idx) => (
                  <Badge key={idx} variant="target">{target}</Badge>
                ))}
              </div>
            </InfoSection>
          )}

          {/* Drug Types */}
          {item.drug_type?.length > 0 && (
            <InfoSection label={
              <span className="font-medium uppercase tracking-wider text-[11px]">Drug Types</span>
            }>
              <div className="flex flex-wrap gap-2">
                {item.drug_type.map((type, idx) => (
                  <Badge key={idx} variant="type">{type}</Badge>
                ))}
              </div>
            </InfoSection>
          )}

          {/* Drug Modality */}
          {item.drug_modality?.length > 0 && (
            <InfoSection label={
              <span className="font-medium uppercase tracking-wider text-[11px]">Drug Modality</span>
            }>
              <div className="flex flex-wrap gap-2">
                {item.drug_modality.map((type, idx) => (
                  <Badge key={idx} variant="type">{type}</Badge>
                ))}
              </div>
            </InfoSection>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default CompeteCard;
