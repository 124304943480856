import React from 'react';

import { useLanguage } from '../../utils/context/lang';

const itemType = {
  'clinical-result': {
    en: 'trials',
    zh: '临床试验',
  },
  'drug-compete': {
    en: 'drugs',
    zh: '药物',
  },
  'conference': {
    en: 'Papers',
    zh: '论文',
  }
}

const selectLimitText = {
  'clinical-result': {
    en: 'You can select up to 5 trials',
    zh: '您最多可以选择5个临床试验',
  },
  'drug-compete': {
    en: 'You can select up to 50 drugs',
    zh: '您最多可以选择50个药物',
  },
  'conference': {
    en: 'You can select up to 5 papers',
    zh: '您最多可以选择5篇论文',
  }
}

const selectLimit = {
  'clinical-result': 5,
  'drug-compete': 50,
  'conference': 5,
}

const SelectedItems = ({ items, onRemove, type = 'conference' }) => {
  const { lang } = useLanguage();
  if (!items?.length) return null;

  return (
    <div className="px-4 py-3 border-b border-base-200">
      <h3 className="text-sm font-medium mb-2">{lang === 'zh' ? '已选择' : 'Selected'} {itemType[type][lang]} - {items.length}/{selectLimit[type]} </h3>
      <div className="flex flex-wrap gap-2">
        {items.map(item => (
          <div 
            key={item.id}
            className="group flex items-center gap-1 px-2 py-1 bg-base-200 rounded-full text-xs"
          >
            <span className="truncate max-w-[150px]">
              {item.title || item.name || item.official_title|| item.citation_title || item.drug_name}
            </span>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onRemove(item);
              }}
              className="opacity-50 hover:opacity-100 p-1"
            >
              ×
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectedItems; 