import DisplayFormattedText from '../../../../AskPage/components/markdownInCell';

export const DRUG_COMPETE_COLUMN = [
  {
    headerName: '#',
    // Convert zero-based rowIndex to a 1-based display:
    valueGetter: (params) => {                         
      return params.node.rowIndex + 1;
    },
    width: 80,
    pinned: 'left',
    sortable: false,
    filter: false,
    suppressMovable: true,
    comparator: () => 0,
    field: 'rowIndex' // Add field name for refresh
  },
  {
    headerName: 'Drug Name',
    field: 'name',
    minWidth: 150,
    width: 150,
    wrapText: true,
    pinned: 'left',
  },
  {
    headerName: 'Other Names',
    field: 'other_names',
    minWidth: 300,
    wrapText: true,
    width: 300,
    cellRenderer: (params) => {
      if (!params.data['other_names']?.length) return null;
      return <DisplayFormattedText text={params.data['other_names'].map(name => `- ${name}`).join('\n')} className="text-xs"/>;
    },
  },
  {
    headerName: 'Lead Company',
    field: 'lead_company',
    minWidth: 200,
    wrapText: true,
    width: 200,
  },
  {
    headerName: 'Partner Companies',
    field: 'partner_companies',
    minWidth: 200,
    wrapText: true,
    width: 200,
    cellRenderer: (params) => {
      if (!params.data['partner_companies']?.length) return null;
      return <DisplayFormattedText text={params.data['partner_companies'].map(company => `- ${company}`).join('\n')} className="text-xs"/>;
    },
  },
  {
    headerName: 'Drug Feature',
    field: 'drug_feature',
    minWidth: 250,
    wrapText: true,
    width: 250,
  },
  {
    headerName: 'Drug Modality',
    field: 'drug_modality',
    minWidth: 200,
    wrapText: true,
    width: 200,
    cellRenderer: (params) => {
      if (!params.data['drug_modality']?.length) return null;
      return <DisplayFormattedText text={params.data['drug_modality'].map(m => `- ${m}`).join('\n')} className="text-xs"/>;
    },
  },
  {
    headerName: 'Target',
    field: 'target',
    minWidth: 200,
    wrapText: true,
    width: 200,
    cellRenderer: (params) => {
      if (!params.data['target']?.length) return null;
      return <DisplayFormattedText text={params.data['target'].map(t => `- ${t}`).join('\n')} className="text-xs"/>;
    },
  },
  {
    headerName: 'Phase',
    field: 'phase',
    minWidth: 110,
    width: 110,
  },
  {
    headerName: 'Indication',
    field: 'indication',
    minWidth: 180,
    wrapText: true,
    width: 180,
  },
  {
    headerName: 'Route of Administration',
    field: 'route_of_administration',
    minWidth: 220,
    wrapText: true,
    width: 220,
    cellRenderer: (params) => {
      if (!params.data['route_of_administration']?.length) return null;
      return <DisplayFormattedText text={params.data['route_of_administration'].map(r => `- ${r}`).join('\n')} className="text-xs"/>;
    },
  }
];
