const NCT_ID = {
  en: 'NCT ID',
  zh: 'NCT ID'
};

const CURRENT_STATUS = {
  en: 'Current Status',
  zh: '当前状态'
};

const PHASE = {
  en: 'Phase',
  zh: '阶段'
};

const DRUG_NAME = {
  en: 'Drug Name',
  zh: '药物名称'
};

const INDICATION = {
  en: 'Indication',
  zh: '适应症'
};

const ENROLLMENT = {
  en: 'Enrollment',
  zh: '入组人数'
};

const STUDY_DESIGN = {
  en: 'Study Design',
  zh: '研究设计'
};

const SAFETY_DATA = {
  en: 'Safety Data',
  zh: '安全性数据'
};

const EFFICACY_DATA = {
  en: 'Efficacy Data',
  zh: '有效性数据'
};

const KEY_FINDINGS = {
  en: 'Key Findings',
  zh: '关键发现'
};

const RELATED_COMPANIES = {
  en: 'Related Companies',
  zh: '相关公司'
};

const LEAD_COMPANY = {
  en: 'Lead Company',
  zh: '主要公司'
};

const PARTNER_COMPANIES = {
  en: 'Partner Companies',
  zh: '合作公司'
};

const SOURCES = {
  en: 'Sources',
  zh: '参考来源'
};

const VIEW_ALL = {
  en: 'View All',
  zh: '查看全部'
};

const MORE_REFERENCES = {
  en: 'more references',
  zh: '更多参考文献'
};

const SITE_NAME = {
  en: 'Source',
  zh: '来源'
};

export const i18n = {
  NCT_ID,
  CURRENT_STATUS,
  PHASE,
  DRUG_NAME,
  INDICATION,
  ENROLLMENT,
  STUDY_DESIGN,
  SAFETY_DATA,
  EFFICACY_DATA,
  KEY_FINDINGS,
  RELATED_COMPANIES,
  LEAD_COMPANY,
  PARTNER_COMPANIES,
  SOURCES,
  VIEW_ALL,
  MORE_REFERENCES,
  SITE_NAME
}