import { Children, Fragment, useEffect, useState } from 'react';


import {
  Dialog,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  Transition,
} from '@headlessui/react';
import {
  Bars3Icon,
  BuildingLibraryIcon,
  ChevronRightIcon,
  Cog8ToothIcon,
  DocumentDuplicateIcon,
  LanguageIcon,
  MagnifyingGlassIcon,
  NewspaperIcon,
  PlusCircleIcon,
  UserCircleIcon,
  UserPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Pill } from '@phosphor-icons/react';
import { Select, Space } from 'antd';
import { motion } from 'framer-motion';
import Cookies from 'js-cookie';
import {
  Link,
  Outlet,
  redirect,
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { getHistoryList, getUserList } from '../api';
import noah_logo_small from '../favicon.png';
import noah_logo_black from '../logo-black.png';
import noah_logo_white from '../logo-white.png';
import { useHistory } from '../utils/context/history';
import { useLanguage } from '../utils/context/lang';
import { useLayout } from '../utils/context/layout';
import { usePro } from '../utils/context/pro';
import { useTheme } from '../utils/context/theme-system';
import { useUser } from '../utils/context/user';

import { i18n } from './i18n/menu';

const langMap = {
  en: 'EN',
  zh: 'CN',
  jp: 'JP',
};

const teams = [
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// TODO MILD: ziwen. add ErrorBoundary
export function MainContainer({ children }) {
  const [ sidebarOpen, setSidebarOpen ] = useState(false);
  const { isSidebarCollapsed, setIsSidebarCollapsed } = useLayout();
  const { user, logout } = useUser();
  const { lang, toggleLanguage, selectLanguage } = useLanguage();
  const { historyResult } = useHistory();
  const { switchHistoryMenu, historyMenuOpen, switchAnswerLang } = usePro();
  const { pathname } = useLocation();
  const [ navigation, setNavigation ] = useState([
    {
      name: i18n.NEW_THREAD[lang],
      href: '/ask/',
      icon: PlusCircleIcon,
      current: false,
    },
    {
      name: i18n.DISCOVER[lang],
      href: '/discover/',
      icon: NewspaperIcon,
      current: false,
    },
    {
      name: i18n.CLINICAL_TRIAL_RESULT[lang],
      href: '/workflow/clinical-result/',
      icon: MagnifyingGlassIcon,
      current: false,
    },
    {
      name: i18n.DRUG_COMPETE[lang],
      href: '/workflow/drug-compete/',
      icon: Pill,
      current: false,
    },
    // {
    //   name: i18n.WORKFLOW[lang],
    //   href: '/workflow/',
    //   icon: MagnifyingGlassIcon,
    //   current: false,
    // },
    {
      name: i18n.CONFERENCE[lang],
      href: '/conference/',
      icon: BuildingLibraryIcon,
      current: false,
    },
    {
      name: i18n.HISTORY[lang],
      href: '/ask/history',
      icon: DocumentDuplicateIcon,
      current: false,
      children: [],
    },
  ]);
  const [ isFirstRender, setIsFirstRender ] = useState(true);
  const [ languageSelectOpen, setLanguageSelectOpen ] = useState(false);
  
  // // reassign the value when lang changes
  // useEffect(() => {
  //   setNavigation((prev) => {
  //     return prev.map((item) => {
  //       if (item.name === i18n.NEW_THREAD[lang]) {
  //         return {
  //           ...item,
  //           name: i18n.NEW_THREAD[lang],
  //         };
  //       }
  //       if (item.name === i18n.HISTORY[lang]) {
  //         return {
  //           ...item,
  //           name: i18n.HISTORY[lang],
  //         };
  //       }
  //       return item;
  //     });
  //   });
  // }, [ lang ]);

  useEffect(() => {
    console.log('historyResult', historyResult);
    if (historyResult && historyResult.length) {
      setNavigation((prev) => {
        return prev.map((item) => {
          if (item.name === i18n.HISTORY[lang]) {
            return {
              ...item,
              children: historyResult.map((item) =>
                item.type === 'thread'
                  ? {
                    name: item.name,
                    href: `/ask/${item.id}`,
                    id: item.id,
                  }
                  : {
                    name: item.name,
                    href: `/workflow/${item.id}`,
                    id: item.id,
                  }
              ),
            };
          }
          return item;
        });
      });
    }
  }, [ historyResult ]);

  useEffect(() => {
    if (pathname) {
      setNavigation((prev) => {
        return prev.map((item) => {
          if (item.href === '/ask/' && pathname.startsWith('/ask/history')) {
            item.current = false;
          } else {
            item.current = pathname.startsWith(item.href.slice(0, -1));
          }
          return item;
        });
      });
    }
  }, [ pathname ]);

  useEffect(() => {
    // Remove first render flag after component mounts
    setIsFirstRender(false);
  }, []);

  const { theme } = useTheme();

  const navigationClick = (href) => {
    if (!isSidebarCollapsed && href !== '/ask/') {
      setIsSidebarCollapsed(true)
    }
    setNavigation((prev) => {
      return prev.map((item) => {
        item.current = item.href === href;
        return item;
      });
    });
  }

  console.log('rendering ask container');
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="font-sans">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="-translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-primary-content"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-base-100 px-6 pb-2 scrollbar-none">
                    <div className="flex h-16 shrink-0 items-center">
                      <a href={`${location.origin}/ask/`}>
                        <img
                          className="h-8 w-auto"
                          src={
                            theme == 'light' ? noah_logo_black : noah_logo_white
                          }
                          alt="Noah"
                        />
                      </a>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-2">
                        {navigation.map((item) => (
                          <li key={item.id}>
                            {!item.children ? (
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? 'bg-gray-50 text-primary'
                                    : 'text-secondary-content hover:text-accent-content hover:bg-accent',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? 'text-primary'
                                      : 'text-neutral-content group-hover:text-primary',
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            ) : (
                              <Disclosure as="div">
                                <DisclosureButton
                                  className={classNames(
                                    item.current
                                      ? 'group flex w-full bg-gray-50 text-primary'
                                      : 'group flex w-full text-secondary-content hover:text-accent-content hover:bg-accent',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                  onClick={() => switchHistoryMenu()}
                                >
                                  <item.icon
                                    aria-hidden="true"
                                    className="h-6 w-6 shrink-0 text-gray-400"
                                  />
                                  {item.name}
                                  <ChevronRightIcon
                                    aria-hidden="true"
                                    className="ml-auto h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                                  />
                                </DisclosureButton>
                                <DisclosurePanel as="ul" className="mt-1 px-2">
                                  {item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      {/* 44px */}
                                      <DisclosureButton
                                        as="a"
                                        href={subItem.href}
                                        className={classNames(
                                          subItem.current
                                            ? 'bg-gray-50'
                                            : 'hover:bg-gray-50',
                                          'block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700'
                                        )}
                                      >
                                        {subItem.name}
                                      </DisclosureButton>
                                    </li>
                                  ))}
                                </DisclosurePanel>
                              </Disclosure>
                            )}
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Toggle button to show expanded sidebar */}
        <motion.button
          onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
          className="fixed top-1/2 -translate-y-1/2 z-50 hidden lg:flex items-center justify-center bg-base-100 transition-colors duration-200"
          initial={false}
          animate={{
            left: isSidebarCollapsed ? '3.5rem' : '18rem',
            gap: '0.25rem',
          }}
          transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
        >
          <motion.div
            initial={false}
            transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
          >
            <div className="relative group">
              <div className="w-4 h-16 bg-base-300/50 backdrop-blur-sm rounded-r-md hover:bg-base-300 transition-all duration-200">
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-1 transition-opacity duration-200 opacity-100">
                  <div className="w-0.5 h-4 bg-secondary-content rounded-full"></div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.button>

        {/* Static sidebar for desktop */}
        <motion.div
          className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col"
          initial={{ width: '18rem', x: 0 }}
          animate={{ 
            width: isSidebarCollapsed ? '3.5rem' : '18rem',
            x: 0
          }}
          transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
        >
          {/* Sidebar component */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-secondary bg-base-100 px-2 scrollbar-none relative">
            {/* Header with logo */}
            <div className="flex h-16 shrink-0 items-center justify-center">
              <Link className='w-full' to='/ask/'>
                <motion.div
                  className="flex items-center"
                  initial={false}
                  animate={{
                    width: '100%',
                  }}
                >
                  <img
                    className="h-8 w-auto object-cover pl-1"
                    // TODO: ziwen. to restore
                    // src={theme == 'light' ? noah_logo_black : noah_logo_white}
                    src={isSidebarCollapsed ? noah_logo_small : theme == 'light' ? noah_logo_black : noah_logo_black}
                    alt="Your Company"
                  />
                </motion.div>
              </Link>
            </div>

            {/* Navigation */}
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-2">
                {navigation.map((item, index) => (
                  <li key={item.name} className='group/tooltip'>
                    <Link
                      to={item.href}
                      onClick={() => navigationClick(item.href)}
                      className={classNames(
                        item.current
                          ? 'bg-accent text-accent-content'
                          : 'text-secondary-content hover:text-accent-content hover:bg-accent',
                        'group flex rounded-md p-2 text-sm leading-6 font-semibold overflow-hidden relative',
                      )}
                    >
                      <motion.div
                        className="flex items-center"
                        initial={false}
                        animate={{
                          x: isSidebarCollapsed ? '50%' : '0%',
                          translateX: isSidebarCollapsed ? '-50%' : '0%',
                        }}
                        transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
                      >
                        <div className="relative">
                          <item.icon
                            className={classNames(
                              item.current
                                ? 'text-primary'
                                : 'text-neutral-content group-hover:text-primary',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                        </div>
                        <motion.span
                          initial={{ opacity: 0, width: 0, marginLeft: 0 }}
                          animate={{ 
                            opacity: isSidebarCollapsed ? 0 : 1,
                            width: isSidebarCollapsed ? 0 : 'auto',
                            marginLeft: isSidebarCollapsed ? 0 : '0.75rem'
                          }}
                          transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
                          className={classNames(
                            item.current ? 'text-accent-content' : '',
                            'overflow-hidden whitespace-nowrap'
                          )}
                        >
                          {item.name}
                        </motion.span>
                      </motion.div>
                    </Link>
                    {/* navigation tooltip */}
                    {isSidebarCollapsed && (
                      <div
                        className="fixed left-14 -translate-y-1/2 pointer-events-none
                                    opacity-0 group-hover/tooltip:opacity-80 
                                    translate-x-[-10px] group-hover/tooltip:translate-x-0
                                    transition-all duration-200 ease-out z-[100]"
                        style={{ top: `${6.5 + (3 * index)}rem` }}
                      >
                        <div className="bg-gray-800/95 backdrop-blur-sm px-3 py-2 
                                      rounded-lg shadow-lg text-primary text-sm font-medium
                                      border border-gray-700/50
                                      flex items-center gap-2 whitespace-nowrap">
                          {item.name}
                        </div>
                      </div>
                    )}
                  </li>
                ))}

                {/* User section at bottom */}
                <li className="-mx-2 mt-auto absolute bottom-0 w-full bg-base-100">
                  <div
                    className={`flex text-sm leading-6 font-semibold overflow-hidden relative text-secondary-content hover:bg-secondary hover:text-secondary-content cursor-pointer ${languageSelectOpen ? 'bg-secondary text-secondary-content' : ''}`}
                  >
                    <motion.div
                      className="flex items-center py-2 pr-6"
                      initial={false}
                      animate={{
                        x: isSidebarCollapsed ? '50%' : '0%',
                        translateX: isSidebarCollapsed ? '-50%' : '0%',
                        paddingLeft: isSidebarCollapsed ? '1rem' : '1.5rem',
                      }}
                      transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
                    >
                      <div className="relative">
                        <LanguageIcon
                          className='h-6 w-6 shrink-0'
                          aria-hidden="true"
                        />
                      </div>
                      <motion.span
                        initial={{ opacity: 0, width: 0, marginLeft: 0 }}
                        animate={{ 
                          opacity: isSidebarCollapsed ? 0 : 1,
                          width: isSidebarCollapsed ? 0 : 'auto',
                          marginLeft: isSidebarCollapsed ? 0 : '0.75rem'
                        }}
                        transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
                        className='overflow-hidden whitespace-nowrap'
                      >
                        {lang === 'en' ? 'English' : '中文'}
                      </motion.span>
                    </motion.div>
                    <Select
                      className="absolute cursor-pointer w-full h-full opacity-0"
                      defaultValue={lang}
                      dropdownStyle={{ width: '6rem', left: isSidebarCollapsed ? '0' : '6rem' }}
                      onChange={(e) => {
                        switchAnswerLang(langMap[e]); // switch explore answer as well.
                        selectLanguage(e);
                      }}
                      onDropdownVisibleChange={(visible) => setLanguageSelectOpen(visible)}
                      options={[
                        { value: 'en', label: 'English' },
                        { value: 'zh', label: '中文' },
                      ]}
                    />
                  </div>
                  <div className="border-r border-t border-secondary">
                    {!user && (
                      <motion.div
                        className="relative text-sm font-semibold leading-6 text-secondary-content cursor-pointer px-2 py-2"
                        initial={false}
                      >
                        <motion.div
                          className="flex flex-col gap-2"
                          initial={false}
                          animate={{
                            width: isSidebarCollapsed ? '2.5rem' : 'auto',
                            alignItems: isSidebarCollapsed ? 'center' : 'stretch',
                            marginLeft: isSidebarCollapsed ? '0rem' : '1.5rem',
                            marginRight: isSidebarCollapsed ? '0rem' : '1.5rem',
                          }}
                          transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
                        >
                          <motion.a
                            href="/signup"
                            className="relative h-10 rounded bg-blue-600 hover:bg-blue-500 text-white flex items-center justify-center overflow-hidden"
                            initial={false}
                            animate={{
                              width: isSidebarCollapsed ? '2.5rem' : '100%',
                            }}
                            transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
                          >
                            <motion.div
                              className="absolute"
                              initial={false}
                              animate={{
                                opacity: isSidebarCollapsed ? 1 : 0,
                                scale: isSidebarCollapsed ? 1 : 0.5,
                              }}
                              transition={isFirstRender ? { duration: 0 } : { duration: 0.2 }}
                            >
                              <UserCircleIcon className="h-5 w-5" />
                            </motion.div>
                            <motion.div
                              className="whitespace-nowrap"
                              initial={false}
                              animate={{
                                opacity: isSidebarCollapsed ? 0 : 1,
                                scale: isSidebarCollapsed ? 0.5 : 1,
                              }}
                              transition={isFirstRender ? { duration: 0 } : { duration: 0.2 }}
                            >
                              {i18n.REGISTER[lang]}
                            </motion.div>
                          </motion.a>

                          <motion.a
                            href="/signin?redirectUrl=ask"
                            className="relative h-10 rounded bg-base-200 hover:bg-base-300 text-secondary-content flex items-center justify-center overflow-hidden"
                            initial={false}
                            animate={{
                              width: isSidebarCollapsed ? '2.5rem' : '100%',
                            }}
                            transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
                          >
                            <motion.div
                              className="absolute"
                              initial={false}
                              animate={{
                                opacity: isSidebarCollapsed ? 1 : 0,
                                scale: isSidebarCollapsed ? 1 : 0.5,
                              }}
                              transition={isFirstRender ? { duration: 0 } : { duration: 0.2 }}
                            >
                              <UserPlusIcon className="h-5 w-5" />
                            </motion.div>
                            <motion.div
                              className="whitespace-nowrap"
                              initial={false}
                              animate={{
                                opacity: isSidebarCollapsed ? 0 : 1,
                                scale: isSidebarCollapsed ? 0.5 : 1,
                              }}
                              transition={isFirstRender ? { duration: 0 } : { duration: 0.2 }}
                            >
                              {i18n.LOGIN[lang]}
                            </motion.div>
                          </motion.a>
                        </motion.div>
                      </motion.div>
                    )}
                    {user?.username && (
                      <div className={classNames(
                        'text-sm font-semibold leading-6 py-4 text-secondary-content cursor-pointer hover:bg-secondary hover:text-secondary-content w-full',
                        isSidebarCollapsed ? 'pr-2' : 'pr-6'
                      )}>
                        <Popover className="relative w-full">
                          <Popover.Button className="relative w-full focus:outline-none">
                            <motion.div
                              className="flex items-center w-full justify-start"
                              initial={false}
                              animate={{
                                paddingLeft: isSidebarCollapsed ? '1rem' : '1.5rem',
                              }}
                              transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
                            >
                              <UserCircleIcon className="h-6 w-6 shrink-0" />
                              <motion.span
                                className="overflow-hidden whitespace-nowrap"
                                initial={{ width: 0, opacity: 0, marginLeft: 0 }}
                                animate={{
                                  width: isSidebarCollapsed ? 0 : 'auto',
                                  opacity: isSidebarCollapsed ? 0 : 1,
                                  marginLeft: isSidebarCollapsed ? 0 : '0.75rem',
                                }}
                                transition={isFirstRender ? { duration: 0 } : { duration: 0.3, ease: 'easeInOut' }}
                              >
                                {user?.username}
                              </motion.span>
                            </motion.div>
                          </Popover.Button>
                          <Popover.Panel className={classNames(
                            'fixed bg-base-100 w-40 px-4 overflow-hidden rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[60] bottom-[4rem]',
                            isSidebarCollapsed 
                              ? 'left-[2rem]'
                              : 'left-[6rem]'
                          )}>
                            {/* <Link
                              className="relative py-2 block text-secondary-content"
                              to="#"
                            >
                              <select
                                onChange={(e) => {
                                  switchAnswerLang(langMap[e.target.value]); // switch explore answer as well.
                                  selectLanguage(e.target.value);
                                }}
                                className="select select-bordered w-full max-w-xs"
                              >
                                <option value="en" selected={lang === 'en'}>
                                  English
                                </option>
                                <option value="zh" selected={lang === 'zh'}>
                                  中文
                                </option>
                              </select>
                            </Link> */}
                            <Link
                              className="relative py-2 block text-secondary-content"
                              to="/contact"
                              target="_blank"
                            >
                              <p>{i18n.FEEDBACK[lang]}</p>
                            </Link>
                            <Link
                              className="relative py-2 block text-secondary-content"
                              to="#"
                              onClick={() => (window.location.href = '/logout')}
                            >
                              <p>{i18n.LOGOUT[lang]}</p>
                            </Link>
                          </Popover.Panel>
                        </Popover>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </motion.div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-base-100 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-secondary-content lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-secondary-content">
            Dashboard
          </div>
          <div>
            <Popover className="relative">
              <Popover.Button className="relative flex justify-center focus:outline-none">
                <span className="sr-only">Your profile</span>
                {/* <img
              className="h-8 w-8 rounded-full bg-gray-50"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            /> */}
                <span aria-hidden="true">{user?.username}</span>

                <Cog8ToothIcon className="h-6 w-6 ui-open:rotate-180 ui-open:transform" />
              </Popover.Button>
              <Popover.Panel className="absolute right-0 w-40 origin-top-right px-4 overflow-hidden rounded-md bg-base-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Link
                  className="relative py-1 pl-3 pr-9 text-secondary-content"
                  to="/contact"
                  target="_blank"
                >
                  <p>{i18n.FEEDBACK[lang]}</p>
                </Link>
                <Link
                  className="relative py-1 pl-3 pr-9 text-secondary-content"
                  to="#"
                  onClick={() => (window.location.href = '/logout')}
                >
                  <p>{i18n.LOGOUT[lang]}</p>
                </Link>
              </Popover.Panel>
            </Popover>
          </div>
        </div>

        <main className={`transition-all duration-300 ${isSidebarCollapsed ? 'lg:pl-14' : 'lg:pl-72'}`}>
          <Outlet />
          {children}
        </main>
      </div>
    </>
  );
}

export async function mainContainerLoader() {
  // const a = await getUserList();
  // TODO MILD: follow https://github.com/remix-run/react-router/discussions/9856
  // currently in loader, we cannot use context or hooks

  //  get user info from localstorage
  console.log('in ask container loader');
  const userName = Cookies.get('userName') || Cookies.get('noahUser'); //noahUser is the same as email. as required by Backend.
  if (userName) {
    console.log('有username!', userName);
    // const data = await getHistoryList();
    // console.log('jbjbjbjb', data)
    // return data||{};
    return userName;
  } else {
    console.log('no username, need login');
    // console.log('登录啊')
    location.replace('/signin');
    return null;
  }

}
