import React, { createContext, useContext, useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import { UserContext } from './user'; // Assuming user.js exports UserContext

const ProContext = createContext();

export const usePro = () => {
  return useContext(ProContext);
};

export const ProProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [ isProOn, setIsProOn ] = useState(false);
  const [ isWebSearchOn, setIsWebSearchOn ] = useState(Cookies.get('isWebSearchOn') || false);
  // Initialize answerLang from cookie or default to 'EN'
  const [ answerLang, setAnswerLang ] = useState(Cookies.get('answerLang') || 'EN');

  useEffect(() => {
    if (user) {
      setIsProOn(user.price_plan === 'pro');
    }
  }, [ user ]);

  const switchPro = () => {
    setIsProOn((prevIsProOn) => !prevIsProOn);
  };

  const switchWebSearch = () => {
    Cookies.set('isWebSearchOn', !isWebSearchOn, { expires: 365 });
    setIsWebSearchOn(prev => !prev);
  };

  const setWebSearch = (value = false) => {
    Cookies.set('isWebSearchOn', value, { expires: 365 });
    setIsWebSearchOn(value);
  };

  const switchAnswerLang = (lang) => {
    setAnswerLang(lang);
    Cookies.set('answerLang', lang, { expires: 365 }); // Set cookie to expire in 1 year
  };

  return (
    <ProContext.Provider value={{ 
      isProOn, 
      switchPro, 
      switchAnswerLang, 
      answerLang,
      isWebSearchOn,
      switchWebSearch,
      setWebSearch
    }}>
      {children}
    </ProContext.Provider>
  );
};
