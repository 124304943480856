import {
  BuildingLibraryIcon,
  MagnifyingGlassIcon,
  NewspaperIcon,
} from '@heroicons/react/24/outline';
import { Pill } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';

import { useLanguage } from '../../utils/context/lang';
import { cards } from '../i18n/cards';

// Common card wrapper component
const CardWrapper = ({ children, className, variant = 'default' }) => {
  const variants = {
    workflow: 'bg-gradient-to-br from-gray-50 to-slate-50 hover:shadow-gray-100/50',
    conference: 'bg-gradient-to-br from-gray-50 to-slate-50 hover:shadow-gray-100/50',
    discover: 'bg-gradient-to-br from-gray-50 to-slate-50 hover:shadow-gray-100/50',
    default: 'bg-gradient-to-br from-gray-50 to-slate-50 hover:shadow-gray-100/50'
  };

  return (
    <div
      className={`group relative flex flex-col overflow-hidden rounded-xl border border-white/50 
        min-h-[240px] shadow-sm hover:shadow-md transition-all duration-200 ${variants[variant]} ${className}`}
    >
      {children}
    </div>
  );
};

const WorkflowCard = ({ workflow }) => {
  const { lang } = useLanguage();
  const cardData = cards[workflow.path]?.[lang];

  if (!cardData) return null;

  return (
    <CardWrapper variant="workflow">
      <div className="flex items-center gap-4 px-4 h-[56px] flex-shrink-0 border-b border-gray-100">
        <Link
          to={`/workflow/${workflow.path}`}
          className="group flex items-center gap-4 flex-1"
        >
          {workflow.path === 'clinical-result' ? (
            <MagnifyingGlassIcon
              className="h-6 w-6 shrink-0 font-semibold text-lg text-gray-600 group-hover:text-gray-700 transition-colors"
              aria-hidden="true"
            />
          ) : (
            <Pill
              className="h-6 w-6 shrink-0 font-semibold text-lg text-gray-600 group-hover:text-gray-700 transition-colors"
              aria-hidden="true"
            />
          )}
          <div className="flex-1">
            <span className="font-semibold text-lg text-gray-600 group-hover:text-gray-700 transition-colors">
              {cardData.title}
            </span>
          </div>
        </Link>
      </div>

      <div className="flex flex-col flex-1">
        <div className="px-4 py-3 h-[60px]">
          <p className="text-sm text-gray-600 line-clamp-2">
            {cardData.description}
          </p>
        </div>

        {cardData.exampleQuestions?.length > 0 && (
          <div className="flex-1 px-4 pb-4 overflow-hidden">
            <div className="h-full overflow-y-auto">
              <div className="space-y-2">
                {cardData.exampleQuestions.map((question, index) => (
                  <Link
                    key={index}
                    to={`/workflow/${workflow.path}?id=${index + 1}`}
                    className="block text-xs text-gray-600 bg-slate-100 backdrop-blur-sm rounded-lg p-2 
                      hover:bg-slate-200 transition-colors"
                  >
                    {question}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </CardWrapper>
  );
};

const ConferenceCard = ({ conference }) => {
  const { lang } = useLanguage();
  const cardData = cards.conferenceInsights?.[lang];

  return (
    <CardWrapper variant="conference">
      <div className="flex items-center gap-4 px-4 h-[56px] flex-shrink-0 border-b border-gray-100">
        <Link
          to="/conference"
          className="group flex items-center gap-4 flex-1"
        >
          <BuildingLibraryIcon
            className="h-6 w-6 shrink-0 font-semibold text-lg text-gray-600 group-hover:text-gray-700 transition-colors"
            aria-hidden="true"
          />
          <div className="flex-1">
            <span className="font-semibold text-lg text-gray-600 group-hover:text-gray-700 transition-colors">
              {cardData?.title || 'Conference Analysis'}
            </span>
          </div>
        </Link>
      </div>

      <div className="flex flex-col flex-1">
        <div className="px-4 py-3">
          <p className="text-sm text-gray-600">
            {cardData?.description || 'Explore insights from major medical conferences and stay updated with the latest research findings.'}
          </p>
        </div>

        <div className="px-4 pb-4">
          {conference?.length > 0 ? (
            <div className="space-y-3">
              {conference.slice(1, 3).reverse().map((conf, index) => (
                <Link
                  key={index}
                  to={conf.info.isActive ? `/conference/${conf.path}` : '#'}
                  className={`group/item flex gap-3 p-2.5 rounded-lg backdrop-blur-sm block
                    ${conf.info.isActive 
                  ? 'bg-slate-100 hover:bg-slate-200 cursor-pointer' 
                  : 'bg-slate-100/50 cursor-not-allowed relative'
                } transition-colors duration-200`}
                  onClick={(e) => {
                    if (!conf.info.isActive) {
                      e.preventDefault();
                    }
                  }}
                >
                  {!conf.info.isActive && (
                    <div className="absolute inset-0 bg-base-200 rounded-lg flex items-center justify-center opacity-0 group-hover/item:opacity-90 transition-opacity z-10">
                      <span className="text-xs text-base-content/70 bg-base-100 px-2 py-1 rounded">
                        Not active yet
                      </span>
                    </div>
                  )}
                  <img
                    src={conf.info.image}
                    alt={conf.name}
                    className={`w-16 h-16 rounded-lg object-contain flex-shrink-0
                      ${!conf.info.isActive ? 'opacity-75' : ''}`}
                  />
                  <div className="flex-1 min-w-0">
                    <h4 className={`text-sm font-medium text-gray-900 line-clamp-2 transition-colors
                      ${!conf.info.isActive ? 'opacity-75' : ''}`}>
                      {conf.info.name}
                    </h4>
                    <p className="text-xs text-gray-500 mt-1">
                      {conf.info.date || 'Upcoming'}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <p className="text-sm text-gray-600">
              No conferences available at the moment.
            </p>
          )}
        </div>
      </div>
    </CardWrapper>
  );
};

const DiscoverCard = ({ article }) => {
  const { lang } = useLanguage();
  const cardData = cards.industryNews?.[lang];

  return (
    <CardWrapper variant="discover">
      <div className="flex items-center gap-4 px-4 h-[56px] flex-shrink-0 border-b border-gray-100">
        <Link
          to="/discover"
          className="group flex items-center gap-4 flex-1"
        >
          <NewspaperIcon
            className="h-6 w-6 shrink-0 font-semibold text-lg text-gray-600 group-hover:text-gray-700 transition-colors"
            aria-hidden="true"
          />
          <div className="flex-1">
            <span className="font-semibold text-lg text-gray-600 group-hover:text-gray-700 transition-colors">
              {cardData?.title || 'Latest Articles'}
            </span>
          </div>
        </Link>
      </div>

      <div className="flex flex-col flex-1">
        <div className="px-4 py-3">
          <p className="text-sm text-gray-600">
            {cardData?.description || 'Discover the latest medical news, research breakthroughs, and industry updates.'}
          </p>
        </div>

        <div className="px-4 pb-4">
          {article?.length > 0 ? (
            <div className="space-y-3">
              {article.slice(0, 2).map((item, index) => (
                <Link
                  key={index}
                  to={`/discover/article/${item.id}`}
                  className="group/item flex gap-3 p-2.5 rounded-lg bg-slate-100 backdrop-blur-sm hover:bg-slate-200 transition-colors duration-200 block"
                >
                  <img
                    src={item.cover_urls}
                    alt={item.title}
                    className="w-16 h-16 rounded-lg object-cover flex-shrink-0"
                  />
                  <div className="flex-1 min-w-0">
                    <h4 className="text-sm font-medium text-gray-900 line-clamp-2 transition-colors">
                      {item.title}
                    </h4>
                    <p className="text-xs text-gray-500 mt-1">
                      {new Date(item.published_date).toLocaleDateString()}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <p className="text-sm text-gray-600">
              No articles available at the moment.
            </p>
          )}
        </div>
      </div>
    </CardWrapper>
  );
};

export { WorkflowCard, ConferenceCard, DiscoverCard };
