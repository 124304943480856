export const selectionStyles = {
  container: 'absolute top-3 right-3 w-6 h-6 z-10',
  checkbox: `w-full h-full rounded-full transition-all duration-300
    hover:scale-110 group-hover:ring-4 group-hover:ring-success/10`,
  selected: `bg-success border-2 border-success shadow-lg shadow-success/30
    scale-105 ring-2 ring-success/20`,
  unselected: `border-2 border-success/40 bg-base-200
    hover:border-success hover:shadow-md
    hover:scale-105 transition-all duration-300`,
  checkIcon: 'w-full h-full text-white p-0.5 drop-shadow'
};
