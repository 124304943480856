import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { getWorkflowList } from '../api';
import { useLanguage } from '../utils/context/lang';

const WorkflowCard = ({ workflow, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="bg-base-100 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 border cursor-pointer hover:-translate-y-1"
    >
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2">{workflow.name}</h3>
        <p className="text-sm text-base-content/70 line-clamp-2">
          {workflow.description}
        </p>
      </div>
    </div>
  );
};

export function WorkflowLanding() {
  const navigate = useNavigate();
  const { lang } = useLanguage();
  const [ workflows, setWorkflows ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const data = await getWorkflowList();
        setWorkflows(data);
      } catch (error) {
        console.error('Failed to fetch workflows:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWorkflows();
  }, []);

  return (
    <div className="min-h-screen bg-base-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold mb-8">Workflows</h1>

        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[ ...Array(6) ].map((_, i) => (
              <div
                key={i}
                className="animate-pulse bg-base-200 h-32 rounded-lg"
              />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {workflows.map((workflow) => (
              <WorkflowCard
                key={workflow.name}
                workflow={workflow}
                onClick={() => navigate(`/workflow/${workflow.path}`)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export async function workflowLandingLoader() {
  const workflows = await getWorkflowList();
  return { workflows };
}
