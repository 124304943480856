import { Fragment, useEffect, useState } from 'react';

import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Switch,
  Transition,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  ArrowPathIcon,
  ArrowRightIcon,
  ChartPieIcon,
  CheckCircleIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  FingerPrintIcon,
  LanguageIcon,
  SquaresPlusIcon,
} from '@heroicons/react/24/outline';

import { useAlert } from '../../utils/context/alert.js'; // Adjust path as needed
import { useLanguage } from '../../utils/context/lang.js';
import { usePro } from '../../utils/context/pro.js';
import { useUser } from '../../utils/context/user.js';
import { i18n as agentDict, languages, placeholder, agents as solutions } from '../const/agent.js';
import { i18n } from '../i18n/common.js';

import FileThumbnail from './fileThumbnail.js'; // Ensure correct import path
import UploadButton from './uploadBtn.js';

// const DEFAULT_TEXTAREA_HEIGHT = 40;
const maxRows = 6;
const people = [
  {
    name: "What's HIV virus?",
    email: 'leslie.alexander@example.com',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: "What's HIV virus?",
    email: 'leslie.alexander@example.com',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: "What's HIV virus?",
    email: 'leslie.alexander@example.com',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: "What's HIV virus?",
    email: 'leslie.alexander@example.com',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: "What's HIV virus?",
    email: 'leslie.alexander@example.com',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    name: "What's HIV virus?",
    email: 'leslie.alexander@example.com',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
];

export default function textInput({
  className,
  onSubmit,
  enableUpload = false,
  disabled = false,
  onFileCountChange,
}) {
  const { lang } = useLanguage();
  const showAlert = useAlert();
  const { isProOn, switchPro, switchAnswerLang, answerLang } = usePro();
  // const { user } = useUser();
  const [ textInput, setTextInput ] = useState('');
  const [ uploadInfos, setUploadInfos ] = useState([]);
  const [ focusArea, setFocusArea ] = useState('mindsearchofficialsite');

  // useEffect(() => {
  //   if (forceSwitchValue !== undefined) {
  //     setSwitchValue(forceSwitchValue);
  //   } else {
  //     setSwitchValue(user ? user.price_plan === 'pro' : false);
  //   }
  // }, [ forceSwitchValue, user ]);

  // useEffect(()=>{
  //   onSwitchChange(switchValue);
  // }, [ switchValue ]);

  useEffect(() => {
    onFileCountChange(uploadInfos.length);
  }, [ uploadInfos ]);

  const submitQuestion = () => {
    console.log('submit question ', {
      textInput,
      uploadInfos,
      isProOn,
      focusArea,
    });
    onSubmit({ textInput, uploadInfos, isProOn, focusArea });
    setUploadInfos([]);
    setTextInput('');
    const textarea = document.querySelector('.resize-none'); // Use a more specific selector if needed
    const container = textarea.parentNode;

    // textarea.style.height = 'auto';  // Reset the height of textarea
    container.style.height = '60px'; // Reset the height of the container to the initial height
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && !e.isComposing && !disabled) {
        if (textInput.trim().length === 0 || e.shiftKey) {
          // If there's no content (trimmed) or Shift is pressed, just add a new line
          return;
        } else {
          // Otherwise, submit the question
          e.preventDefault(); // Prevent form submission
          submitQuestion();
        }
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [ textInput, disabled ]); // Include `disabled` to ensure the effect updates if the disabled state changes

  const handleSelectFile = (file) => {
    console.log('File selected:', file.name);
    setUploadInfos((prev) => [ ...prev, { name: file.name } ]);
  };
  const handleTextChange = (e) => {
    const textarea = e.target;
    const container = textarea.parentNode; // Assuming the parent node is the container div
    container.style.height = 'auto';
    // Calculate effective height considering padding
    const computedStyle = window.getComputedStyle(textarea);
    const paddingTop = parseInt(computedStyle.paddingTop, 10);
    const paddingBottom = parseInt(computedStyle.paddingBottom, 10);
    const borderHeight =
      parseInt(computedStyle.borderTopWidth, 10) +
      parseInt(computedStyle.borderBottomWidth, 10);

    // Calculate the height to be the greater of scrollHeight (less padding and border) or the minimum height
    const desiredHeight = Math.max(
      textarea.scrollHeight - paddingTop - paddingBottom - borderHeight,
      60
    );

    container.style.height = `${desiredHeight}px`;
    setTextInput(textarea.value); // Update the state with new input
  };
  const handleFileUploaded = (file, uploadData) => {
    console.log('selectedFile', file);
    console.log('File uploaded:', uploadData);
    setUploadInfos((prev) =>
      prev.map((info) =>
        info.name === file.name
          ? {
            ...info,
            upload: uploadData.upload,
            id: uploadData.id,
            name: file.name,
          }
          : info
      )
    );
  };

  const deleteUpload = (fileId) => {
    setUploadInfos((prev) => prev.filter((info) => info.id !== fileId));
  };

  // const onModelChange = (newVal) => {
  //   // if (newVal) {
  //   //   if (user?.price_plan === 'pro') {
  //   //     setSwitchValue(newVal);
  //   //   } else {
  //   //     // optimize this part
  //   //     showAlert('error', i18n.UPGRADE_HINT[lang]);
  //   //   }
  //   // } else {
  //   setSwitchValue(newVal);

  //   // }
  // };

  return (
    <div
      className={`form-control border border-gray-300 rounded-lg bg-base-100 ${className}`}
    >
      <div className="flex overflow-x-scroll mx-2 scrollbar-none">
        {uploadInfos.map((upload, index) => (
          <FileThumbnail
            key={upload.id || upload.name}
            upload={upload}
            onDelete={() => deleteUpload(upload.id)}
          />
        ))}
      </div>
      <div className="relative input-group flex items-center justify-center overflow-hidden h-[60px] max-h-[160px]">
        <textarea
          id="textarea"
          placeholder={placeholder[lang]}
          rows={1}
          className="relative scrollbar-none pt-4 h-full min-h-[70px] max-h-[160px] resize-none leading-6 input w-[calc(100%-1rem)] border-none focus:outline-none focus:shadow-none focus:ring-0 overflow-y-scroll"
          value={textInput}
          onChange={handleTextChange}
        />
      </div>
      <div className="relative flex items-center justify-between">
        <div className="relative flex items-center">
          <Popover className="relative pl-5">
            <PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-primary-content outline-none">
              <span>{solutions.find(solution=>solution.category===focusArea)[`role_${lang}`]}</span>
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
            </PopoverButton>

            <PopoverPanel
              transition
              className="absolute left-0 z-10 mt-2 flex w-screen max-w-max px-2 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              {({ close }) => (
                <div className="w-screen max-w-md flex-auto overflow-hidden rounded-xl bg-base-100 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
                    {solutions
                      .filter((solution) => solution.inLanding)
                      .map((item) => (
                        <div
                          key={item.category}
                          onClick={() => {
                            setFocusArea(item.category);
                            close();
                          }}
                          className="group relative gap-x-6 rounded-lg p-4 hover:bg-base-200 cursor-pointer"
                        >
                          <div className="mt-1 flex w-full flex-none items-center justify-center rounded-lg">
                            <item.icon
                              aria-hidden="true"
                              className="h-6 w-6 mr-4 text-secondary-content"
                            />
                            <a
                              href={item.href}
                              className="font-semibold flex-1 text-secondary-content"
                            >
                              {item[`role_${lang}`]} 
                            </a>
                          </div>
                          <p className="mt-1 text-secondary-content ml-10 line-clamp-2">
                            {agentDict[item.category][`description_${lang}`]}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </PopoverPanel>
          </Popover>

          {/* {enableUpload && (
            <UploadButton
              className=""
              onSelectFile={handleSelectFile}
              onFileUploaded={handleFileUploaded}
              onUploadError={(error) => showAlert('error', 'File upload error')}
              upload={uploadInfos}
              isMini={false}
            />
          )} */}
        </div>        
        <div className="relative flex items-center">
          <div className="relative group">
            <Popover className="relative pl-5">
              <PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-primary-content outline-none">
                <LanguageIcon aria-hidden="true" className="h-5 w-5" />
                <span className="ml-2 w-12">{languages.find(language=>language.code===answerLang)?.name}</span>
                <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
              </PopoverButton>

              <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden group-hover:block">
                <div className="bg-gray-900 text-white text-sm rounded-md py-1 px-2 whitespace-nowrap">
                  Change answer language
                  <div className="absolute top-full left-1/2 -translate-x-1/2 border-4 border-transparent border-t-gray-900"></div>
                </div>
              </div>

              <PopoverPanel className="absolute top-0 transform -translate-y-full mt-[-8px] left-0 z-10 w-48 bg-base-100 rounded-lg shadow-lg ring-1 ring-gray-200 py-1">
                {languages.map(language => (
                  <button
                    key={language.code}
                    className="w-full px-4 py-2 text-left text-sm"
                    onClick={() => switchAnswerLang(language.code)}
                  >
                    {language.name}
                  </button>
                ))}
              </PopoverPanel>
            </Popover>
          </div>
          {/* <>
            <Switch
              checked={isProOn}
              onChange={switchPro}
              className={`${
                isProOn ? 'bg-blue-500' : 'bg-gray-200'
              } relative mr-1 ml-1 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${
                  isProOn ? 'translate-x-5' : 'translate-x-0'
                } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
            <span>Pro</span>
          </> */}
          <button
            type="button"
            className="rounded-full disabled:bg-gray-300 bg-brand p-1.5 text-white shadow-sm hover:bg-blue-600 mx-4 my-1"
            onClick={submitQuestion}
            disabled={textInput.length === 0 || disabled}
          >
            <ArrowRightIcon aria-hidden="true" className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
}
