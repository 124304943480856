import { useState } from 'react';

import { CheckBoxIcon, CheckBoxOutlineBlankIcon, CheckIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';

import { selectionStyles } from '../../styles/selection';

const PaperCard = ({ paper, isSelected, onClick }) => {
  const handleTitleClick = (e) => {
    // Prevent the click event from bubbling up to the card
    e.stopPropagation();
  };

  return (
    <motion.div
      onClick={onClick}
      whileHover={{ y: -2 }}
      whileTap={{ scale: 0.98 }}
      className={`bg-base-100 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 border cursor-pointer relative
        ${isSelected ? 'border-primary ring-1 ring-primary/30' : 'border-base-200'}`}
    >
      {/* Selection Indicator */}
      <div className={selectionStyles.container}>
        <div className={`${selectionStyles.checkbox} 
          ${isSelected ? selectionStyles.selected : selectionStyles.unselected}`}
        >
          {isSelected && (
            <svg className={selectionStyles.checkIcon} viewBox="0 0 24 24">
              <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
            </svg>
          )}
        </div>
      </div>

      <div className="p-4">
        {/* Header */}
        <div className="mb-3">
          <div className="flex items-center justify-start mb-2 mr-6">
            {paper.presentation_type && (
              <span className="px-2 py-1 text-xs rounded-full bg-base-200 text-base-content/60">
                {paper.presentation_type}
              </span>
            )}
          </div>
          <h3 className="text-lg font-semibold mb-3">
            <a 
              href={paper.link || 'https://apsard.org/wp-content/uploads/2025/01/2025-APSARD-Abstract-Book.pdf'} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="hover:underline"
              onClick={handleTitleClick}
            >
              {paper.citation_title}
            </a>
          </h3>
        </div>

        {/* Author Info */}
        <div className="text-sm text-base-content/70 mb-4">
          <div className="flex items-center gap-2">
            <span className="font-medium truncate">{paper.citation_author}</span>
            <span className="text-base-content/30">·</span>
            <span className="text-xs truncate text-base-content/50">{paper.citation_author_institution}</span>
          </div>
        </div>

        {/* Tags Section */}
        <div className="mb-4">
          <div className="flex flex-wrap gap-1.5">
            {/* Primary Tags */}
            {paper.drug.map((drug, idx) => (
              drug !== 'Not Reported' && (
                <span key={idx} className="px-2 py-0.5 text-xs rounded-full bg-success/10 text-success">
                  {drug}
                </span>
              )
            ))}
            {paper.target.map((target, idx) => (
              target !== 'Not Reported' && (
                <span key={idx} className="px-2 py-0.5 text-xs rounded-full bg-info/10 text-info">
                  {target}
                </span>
              )
            ))}
            {/* Secondary Tags */}
            <span className="px-2 py-0.5 text-xs rounded-full bg-base-200 text-base-content/60">
              {paper.design}
            </span>
            <span className="px-2 py-0.5 text-xs rounded-full bg-base-200 text-base-content/60">
              {paper.disease}
            </span>
          </div>
        </div>

        {/* Key Findings */}
        <div>
          <h4 className="text-xs font-medium uppercase tracking-wider text-base-content/50 mb-2">Key Points</h4>
          <ul className="list-disc list-inside text-sm text-base-content/70 space-y-1.5">
            {paper.summary.map((point, idx) => (
              <li key={idx} className="leading-relaxed transition-all duration-200">
                {point}
              </li>
            ))}
          </ul>
        </div>

        {/* Footer */}
        <div className="mt-4 pt-3 border-t border-base-200">
          <div className="text-xs text-base-content/40 text-center">
            {paper.study_type}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default PaperCard;
