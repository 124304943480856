// Might also rename as Toast
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import ReactDOM from 'react-dom';

// TODO: ziwen. will only be one alert, might need factory mode in the future
const AlertContext = React.createContext(null);

export const useAlert = () => {
  return useContext(AlertContext);
};

export const AlertProvider = ({ children }) => {
  const [ alert, setAlert ] = useState({ type: null, text: null });

  const showAlert = useCallback((type, text, specifics) => {
    console.log('in provider', { type, text, specifics })
    setAlert({ type, text, specifics });
  }, []);

  const closeAlert = useCallback(() => {
    setAlert({ type: null, text: null, specifics: [] });
  }, []);

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      {alert.text && ReactDOM.createPortal(
        <Alert type={alert.type} text={alert.text} specifics={alert?.specifics} onDismiss={closeAlert} />,
        document.body
      )}
    </AlertContext.Provider>
  );
};


function Alert({ type, text, specifics, onDismiss }) {
  useEffect(() => {
    console.log('in alert component')
    const timer = setTimeout(() => {
      onDismiss();
    }, 5000); // Auto-dismiss after 3 seconds

    return () => {
      clearTimeout(timer); // Clear the timeout if the alert is manually closed before the timeout
    };
  }, [ onDismiss ]);

  // HAVE TO USE FULL NAME https://github.com/tailwindlabs/tailwindcss/discussions/11189
  const bgColorMap = {
    'success': 'bg-green-50',
    'info': 'bg-blue-50',
    'error': 'bg-red-50',
  };
  const hoverBgColorMap = {
    'success': 'hover:bg-green-100',
    'info': 'hover:bg-blue-100',
    'error': 'hover:bg-red-100',
  }
  const textColorMap = {
    'success': 'text-green-800',
    'info': 'text-blue-800',
    'error': 'text-red-800',
  }

  const specificsList = specifics ? specifics.map((item, index) => <li key={index}>{item}</li>) : null;
  return (
    <div className={`z-[100] fixed bottom-0 left-0 w-96 rounded-md ${bgColorMap[type]} p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {type === 'success' && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
          {type === 'info' && <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />}
          {type === 'error' && <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />}
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium ${textColorMap[type]}`}>{text}</h3>
          {/* <div className="mt-2 text-sm text-green-700">
            <p>{text}</p>
          </div> */}

          <div className={`mt-2 text-sm ${textColorMap[type]}`}>
            <ul role="list" className="list-disc space-y-1 pl-5">
              {specificsList}
            </ul>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              {/* <button
                type="button"
                className={`rounded-md bg-${colorMap[type]}-50 px-2 py-1.5 text-sm font-medium text-${colorMap[type]}-800 hover:bg-${colorMap[type]}-100 focus:outline-none focus:ring-2 focus:ring-${colorMap[type]}-600 focus:ring-offset-2 focus:ring-offset-${colorMap[type]}-50`}
              >
                View status
              </button> */}
              {/* <button
                type="button"
                className={`ml-3 rounded-md ${bgColorMap[type]} px-2 py-1.5 text-sm font-medium ${textColorMap[type]} ${hoverBgColorMap[type]} focus:outline-none focus:ring-2 focus:ring-offset-2`}
              >
                Dismiss
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}