import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import Cookies from 'js-cookie';

const WebSocketContext = createContext();

// TODO: ziwen. we should be able to add more context to this provider, to handle latestMessage in a versatile way
export const WebSocketProvider = ({ children }) => {
  const [ isSocketOpen, setIsSocketOpen ] = useState(false);
  const [ latestMessage, setLatestMessage ] = useState(null);
  const client = useRef(null);

  useEffect(() => {
    let reconnectTimeout;
    let heartbeatInterval;

    const token = Cookies.get('noahAccessToken');
    const wsBaseURL = `wss://${window.location.host.includes('localhost')
      ? process.env.REACT_APP_API_URL.replace('http://', '').replace('https://', '') // Local dev
      : window.location.host
    }/ws/chat/`;

    const connectWebSocket = () => {
      if (client.current && client.current.readyState === WebSocket.OPEN) {
        console.warn('WebSocket is already connected.');
        return;
      }

      client.current = new WebSocket(`${wsBaseURL}?token=${token}`);

      client.current.onopen = () => {
        console.warn('WebSocket connected.');
        setIsSocketOpen(true);
        client.current.send(JSON.stringify({ type: 'ping' }));
        heartbeatInterval = setInterval(() => {
          if (client.current.readyState === WebSocket.OPEN) {
            client.current.send(JSON.stringify({ type: 'ping' }));
          }
        }, 20000);
      };

      client.current.onclose = () => {
        console.warn('WebSocket disconnected.');
        setIsSocketOpen(false);
        clearInterval(heartbeatInterval);
        reconnectTimeout = setTimeout(connectWebSocket, 5000);
      };

      client.current.onerror = (error) => {
        console.warn('WebSocket error:', error);
        setIsSocketOpen(false);
      };

      client.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data === 1) { // handle ping message (currently use 1 as ping message)
          return;
        }
        setLatestMessage(data); // Only store the latest message
      };
    };

    connectWebSocket();

    return () => {
      clearTimeout(reconnectTimeout);
      clearInterval(heartbeatInterval);
      if (client.current) {
        client.current.close();
        client.current = null;
      }
    };
  }, []);

  const sendMessage = (message) => {
    if (client.current && client.current.readyState === WebSocket.OPEN) {
      client.current.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not open.');
    }
  };

  const clearLatestMessage = () => {
    setLatestMessage(null);
  };

  return (
    <WebSocketContext.Provider value={{ 
      isSocketOpen, 
      latestMessage, 
      sendMessage,
      clearLatestMessage 
    }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};
