import React from 'react';

import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { selectionStyles } from '../../styles/selection';

const Badge = ({ children, variant = 'default' }) => {
  const variants = {
    phase: 'bg-success/10 text-success',
    drug: 'bg-primary text-primary-content',
    disease: 'bg-info/10 text-info',
    status: 'bg-warning/10 text-warning',
    default: 'bg-base-200 text-base-content/60'
  };

  return (
    <span className={`px-2 py-0.5 text-xs font-medium rounded-md ${variants[variant]}`}>
      {children}
    </span>
  );
};

const TrialCard = ({ item, isSelected, onClick }) => {
  const highlightNumbers = (text) => {
    if (!text?.finding) return text;
    return text.finding.split(/(\s|^)(\d+(?:\.\d+)?%|\d+\.\d+)/).map((part, index) => {
      if (part.match(/^(\d+(?:\.\d+)?%|\d+\.\d+)$/)) {
        return (
          <span key={index} className="text-primary-content font-medium">
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <motion.div
      onClick={onClick}
      whileTap={{ scale: 0.98 }}
      className={`bg-base-100 rounded-lg overflow-hidden relative cursor-pointer transition-colors duration-200
        ${isSelected 
      ? 'ring-1 ring-primary border border-primary' 
      : 'border border-base-200 hover:border-base-300'}`}
    >
      {/* Selection Indicator */}
      <div className={selectionStyles.container}>
        <div className={`${selectionStyles.checkbox} 
          ${isSelected ? selectionStyles.selected : selectionStyles.unselected}`}
        >
          {isSelected && (
            <svg className={selectionStyles.checkIcon} viewBox="0 0 24 24">
              <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
            </svg>
          )}
        </div>
      </div>
      <div className="p-4">
        {/* Header without Selection Indicator */}
        <div className="mb-3 pr-6">
          <div className="space-y-1">
            <a 
              href={`/detail/clinical-trial/${item.id}`}
              target="_blank" 
              rel="noopener noreferrer" 
              className="hover:underline"
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="text-base font-medium text-base-content">
                {item.official_title}
              </h3>
            </a>
            <div className="text-sm text-base-content/70">
              {item.lead_company}
            </div>
          </div>
        </div>

        {/* Status Badges */}
        <div className="flex flex-wrap gap-2 mb-4">
          {item.drug_name && (
            <Badge variant="drug">{item.drug_name}</Badge>
          )}
          {item.indication_name && (
            <Badge variant="disease">{item.indication_name}</Badge>
          )}
          {item.phase && (
            <Badge variant="phase">Phase {item.phase}</Badge>
          )}
          {item.current_status && (
            <Badge variant="status">{item.current_status}</Badge>
          )}
        </div>

        {/* Key Findings */}
        {item.key_findings?.length > 0 && (
          <div className="pt-3 border-t border-base-200">
            <div className="text-xs font-medium text-base-content/50 mb-2">
              Key Findings
            </div>
            <ul className="space-y-2 list-disc pl-4 marker:text-base-content/30">
              {item.key_findings.map((finding, idx) => (
                <li key={idx} className="text-sm text-base-content/70">
                  {highlightNumbers(finding)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default TrialCard;
