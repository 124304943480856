import DisplayFormattedText from '../../../../AskPage/components/markdownInCell';

export const CLINICAL_RESULT_COLUMN = [
  {
    headerName: '#',
    // Convert zero-based rowIndex to a 1-based display:
    valueGetter: (params) => {                         
      return params.node.rowIndex + 1;
    },
    width: 80,
    pinned: 'left',
    sortable: false,
    filter: false,
    suppressMovable: true,
    comparator: () => 0,
    field: 'rowIndex' // Add field name for refresh
  },
  {
    headerName: 'Trial ID',
    field: 'nct_id',
    minWidth: 130,
    width: 130,
    pinned: 'left',
    cellRenderer: (params) => {
      return (
        <a
          href={`https://clinicaltrials.gov/study/${params.data['nct_id']}`}
          target="_blank"
          rel="noreferrer"
          className="underline text-blue-500 hover:text-blue-700 focus:outline-none focus:underline"
        >
          {params.data['nct_id']}
        </a>
      );
    },
  },
  {
    headerName: 'Official Title',
    field: 'official_title',
    minWidth: 300,
    width: 300,
    cellRenderer: (params) => {
      return (
        <a
          href={`/detail/clinical-trial/${params.data.id}`}
          target="_blank" 
          rel="noopener noreferrer" 
          className="block whitespace-pre-wrap hover:underline"
          onClick={(e) => e.stopPropagation()}
        >
          {params.value}
        </a>
      );
    },
  },
  {
    headerName: 'Drug Name',
    field: 'drug_name',
    wrapText: true,
    minWidth: 140,
    width: 140,
  },
  {
    headerName: 'Enrollment',
    field: 'actual_enrollment',
    minWidth: 126,
    width: 126,
    wrapText: true,
  },
  {
    headerName: 'Lead Company',
    field: 'lead_company',
    minWidth: 200,
    width: 200,
    wrapText: true,
  },
  {
    headerName: 'Partner Companies',
    field: 'partner_companies',
    minWidth: 200,
    width: 200,
    wrapText: true,
    cellRenderer: (params) => {
      // return params.data['partner_companies']?.map(item => `- ${item}`).join('\n');
      return <DisplayFormattedText text={params.data['partner_companies']?.map(item => `- ${item}`).join('\n')} className="text-xs"/>;
    },
  },
  { headerName: 'Indication', minWidth: 180, field: 'indication_name', wrapText: true, width: 180 },
  { headerName: 'Phase', minWidth: 110, field: 'phase', width: 110 },
  // { headerName: 'Actual Enrollment', field: 'actual_enrollment' },
  { headerName: 'Target', minWidth: 200, field: 'target', wrapText: true, width: 200 },
  { headerName: 'Current Status', minWidth: 160, field: 'current_status', width: 160 },
  {
    headerName: 'Design',
    field: 'design',
    minWidth: 600,
    wrapText: true,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['design']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Efficacy',
    field: 'efficacy',
    minWidth: 600,
    wrapText: true,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['efficacy']} className="text-xs"/>;
    },
  },
  {
    headerName: 'Safety',
    field: 'safety',
    minWidth: 600,
    wrapText: true,
    cellRenderer: (params) => {
      return <DisplayFormattedText text={params.data['safety']} />;
    },
  },
  {
    headerName: 'Sources',
    field: 'source',
    minWidth: 300,
    cellRenderer: (params) => {
      return (
        <ul>
          {params.data['sources']?.map((source, index) => (
            <li key={index}>
              {index + 1}. <a
                href={source.link}
                target="_blank"
                rel="noreferrer"
                className="underline text-blue-500 hover:text-blue-700 focus:outline-none focus:underline"
              >
                {source.title}
              </a>
            </li>
          ))}
        </ul>
      );
    },
  },
  { headerName: 'Location', field: 'locations', tooltipField: 'locations', minWidth: 200, wrapText: true, },
  // {
  //   headerName: 'Last Updated',
  //   field: 'last_updated',
  //   minWidth: 150,
  // },
];
