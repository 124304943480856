import React, { useState } from 'react';

import { CalendarIcon, MapPinIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { getConferenceList } from '../api';
import { useAlert } from '../utils/context/alert';
import { useLanguage } from '../utils/context/lang';

import { i18n } from './i18n/conference';

const MeetingCard = ({ meeting, onConferenceClick }) => {
  const info = meeting.info;
  
  return (
    <div
      onClick={() => onConferenceClick(meeting)}
      className={`group bg-base-100 rounded-xl overflow-hidden border border-base-200 
        transition-all duration-300 relative
        ${info.isActive 
      ? 'hover:shadow-lg cursor-pointer' 
      : 'opacity-75 hover:opacity-100'
    }`}
    >
      {/* Inactive Overlay */}
      {!info.isActive && (
        <div className="absolute inset-0 bg-base-200/50 backdrop-blur-[1px] z-10 
          flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
          <div className="bg-base-100 rounded-lg shadow-lg p-4 transform translate-y-4 
            group-hover:translate-y-0 transition-transform duration-300">
            <p className="text-base-content/70 text-sm font-medium">
              This conference is not active yet
            </p>
          </div>
        </div>
      )}

      {/* Content (slightly dimmed when inactive) */}
      <div className={`relative ${!info.isActive ? 'opacity-75' : ''}`}>
        {/* Header Image */}
        <div className="aspect-video bg-gradient-to-br from-primary/5 to-secondary/5 relative overflow-hidden">
          <img src={info.image} alt={info.name} className="object-contain w-full h-full" />
        </div>

        {/* Content */}
        <div className="p-4">
          {/* Title and Status */}
          <div className="flex justify-between items-start mb-4">
            <h3 className="text-lg font-semibold line-clamp-2 flex-1">
              {info.name}
            </h3>
            <span className={`
              px-2 py-1 text-xs rounded-full ml-2 whitespace-nowrap
              ${info.status === 'upcoming' ? 'bg-success/10 text-success' : 'bg-base-200 text-base-content/70'}
            `}>
              {info.status}
            </span>
          </div>

          {/* Info List */}
          <div className="space-y-2 text-sm text-base-content/70">
            <div className="flex items-center gap-2">
              <CalendarIcon className="w-4 h-4" />
              <span>{info.date}</span>
            </div>
            <div className="flex items-center gap-2">
              <MapPinIcon className="w-4 h-4" />
              <span>{info.location}</span>
            </div>
            <div className="flex items-center gap-2">
              <UserGroupIcon className="w-4 h-4" />
              <span>{info.organization}</span>
            </div>
          </div>

          {/* Description */}
          <p className="mt-4 text-sm text-base-content/70 line-clamp-2">
            {info.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export function ConferenceHistory() {
  const { lang } = useLanguage();
  const conferences = useLoaderData();
  console.log('jijijijiji', conferences);
  const navigate = useNavigate();
  const showAlert = useAlert();

  const handleConferenceClick = (meeting) => {
    if (meeting.info.isActive) {
      navigate(`/conference/${meeting.path}`);
    } else {
      showAlert('error', 'This conference is not active yet.');
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col gap-8">
        <h1 className="text-3xl font-bold">{i18n.TITLE[lang]}</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {conferences.map((meeting) => (
            <MeetingCard
              key={meeting.path}
              meeting={meeting}
              onConferenceClick={() => handleConferenceClick(meeting)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export async function conferenceHistoryLoader() {
  const conferences = await getConferenceList();
  return conferences;
}
